import React,{ useEffect } from 'react';
import M from "materialize-css";

import { Navbar } from '../UI/Navbar';
import { Footer } from '../UI/Footer';
import { ModalCotizar } from '../UI/ModalCotizar';

import '../../styles/ServicesRoutes/ServicesStyles.css'

export const AseguramientoMetrologico = () => {

    useEffect(()=>{
        const carousel = document.querySelectorAll('.carousel');
        M.Carousel.init(carousel,{ indicators:true, fullWidth:false });
    },[]);


    return (
        <>
            <Navbar />

            <div
                className="background-services"
                style={{
                    backgroundImage: 'url(./assets/Fondo3.jpg)'
                }}
            >

                <div className="container service__container">

                    <div className="row">

                        <div className="col s12 m6 l6">
                            <img 
                                src={ './assets/img/aseguramientos/aseguramiento2.jpg' } 
                                alt="Imagen Servicio"
                                className="service__image"
                            />

                            {/* <div className="carousel">
                                <a className="carousel-item" style={{
                                    width:'100%'
                                }} ><img src={`./assets/img/aseguramientos/aseguramiento1.jpg`}/></a>
                                <a className="carousel-item" style={{
                                    width:'100%'
                                }} ><img src={`./assets/img/aseguramientos/aseguramiento2.jpg`}/></a>
                                <a className="carousel-item" style={{
                                    width:'100%'
                                }} ><img src={`./assets/img/aseguramientos/aseguramiento3.jpg`}/></a>
                            </div> */}

                        </div>

                        <div className="col s12 m6 l6">

                            <h4 className="service__label-title blue-text mb-0">Aseguramiento Metrológico</h4>
                        
                            <br />
                            <ul className="center-align mt-0 label-card-desc list-mantenimiento">
                                <li className="list-services service__label-list green-text" >Servicio de calibración trazable al sistema internacional de unidades.</li>
                                <li className="list-services service__label-list green-text" >Copia de trazabilidad de los patrones utilizados y pasantías de los metrologos que realizan las mediciones.</li>
                                <li className="list-services service__label-list green-text" >Informe de calibración con estimación de incertidumbre según la GUM.</li>
                                <li className="list-services service__label-list green-text" >Sticker de calibración.</li>
                            </ul>
                            <br />
                            <div className="service__label-btn">
                                <button 
                                    className="btn-large center-align blue modal-trigger"
                                    data-target="modal1"
                                >
                                    Cotizar
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <Footer />

            <ModalCotizar />
        </>
    )
}
