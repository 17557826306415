import React, {useContext} from 'react'
import { Route, Switch, Redirect } from 'react-router-dom';
import { Auth } from '../../auth/AuthContext';
import { BuscarReporte } from '../../components/AdministrarReportes/BuscarReporte';
import { EditarReporte } from '../../components/AdministrarReportes/EditarReporte.js/EditarReporte';
import { EditarFactura } from '../../components/FacturasScreen/EditarFactura';


export const BuscarReportes = () => {
    const { user } = useContext(Auth);

    if(!user) return <Redirect to="/login" />;

    return (
        <Switch>
            <Route exact path="/buscar-reportes" component={BuscarReporte} />
            <Route exact path="/editar" component={EditarReporte} />
            <Route exact path="/editar-factura" component={ EditarFactura } />


            <Redirect to="/" />
        </Switch>
    )
}

