import React from 'react';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";

import { IndexScreen } from '../components/IndexScreen/IndexScreen';
import { Login } from '../components/LoginScreen/Login';
import { MainRoutesPanel } from './MainRoutesPanel';

export const Main = () => {

    return (
        <div>
            <Router >

                <Switch>

                    <Route exact path="/" component={IndexScreen} />
                    <Route exact path="/login" component={ Login }  />

                    <Route component={ MainRoutesPanel } />


                    <Redirect to="/" />
                </Switch>
            </Router>
        </div>
    )
}
