import React, { useState, useEffect } from 'react';
import {  useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { getFactura } from '../../api/Facturas/FacturaHelpers';
import { FacturasIndex } from './FacturasIndex';



export const EditarFactura = ({history}) => {
    const location = useLocation();
    const { id } = queryString.parse(location.search);
    const [initializing, setInitializing] = useState(true);


    const [ factura, setFactura ] = useState();

    useEffect(()=>{
        const getData = async() =>{
            const data = await getFactura(id)
            setFactura(data)
            setInitializing(false)
        }
        getData();

    },[id]);

    if(initializing) return null;

    return <FacturasIndex data={factura} history={history}/>
}
