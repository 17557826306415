import React from 'react';
import { useHistory } from 'react-router-dom';
import { borrarInforme } from '../../api/AdministrarReportes/borrarInforme';
import '../../styles/AdministrarReportes/CardReporte.css';
import swal from 'sweetalert';


export const CardInforme = ({ cliente,equipo,fecha, informe_url ,id, modificacion }) => {
    const history = useHistory();

    const handleEditar = () =>{
        history.push(`/editar?id=${id}`);
    }

    const handleDelete = () =>{
        swal({
            title: "Estas Seguro?",
            text: "Una vez borrado el informe no se podrá recuperar",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then(async(Borrar) => {
            if (Borrar) {
                try {
                    await borrarInforme(id);
                    swal({
                        title: "Excelente",
                        text: "El informe fue borrado sastisfactoriamente",
                        icon: "success",
                    });
                    history.go(0)
                } catch (e) {
                    console.error(e);
                }
            } 
        });

    }

    return (

        
        <div className="contenedor-card-informe">
            <div className="row card-informe">
                
                <div className="col l2 m2 s12">
                    <h6 className="center-align m-0" >{cliente}</h6>
                </div>
                <div className="col l2 m2 s12">
                    <h6 className="center-align">{equipo}</h6>
                </div>
                <div className="col l4 m4 s12">
                    <p className="center-align m-0">Fecha Informe:</p>
                    <p className="center-align m-0">{fecha}</p>
                </div>
                <div className="col l4 m4 s12">
                    <div className="btns-contenedor">
                        <button className="waves-effect blue waves-light btn-small" onClick={()=>handleEditar()}>
                            <i className="fas fa-edit"/>
                        </button>

                        {
                            informe_url ? (
                                <a  href={informe_url} 
                                className="waves-effect green waves-light btn-small" target="_blank"
                                rel="noreferrer"
                                >
                                    <i className="fas fa-file-download"/>
                                </a>
                            ) : (
                                'N/A'
                            )
                        }
                        <button onClick={()=>handleDelete()} className="waves-effect red waves-light btn-small">
                            <i className="fas fa-trash" />
                        </button>
                    </div>
                </div>
            </div>
            <p className="right-align label-elaborado grey-text">{modificacion}</p>
        </div>
    )
};
