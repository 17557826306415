import React from 'react';

import { Navbar } from '../UI/Navbar';
import { Presentacion } from './Presentacion';
import { Servicios } from './Servicios';
import { Clientes } from './Clientes';
import { Contactanos } from './Contactanos';
import { Footer } from '../UI/Footer';

export const IndexScreen = () => {


    return (
        <>
            <Navbar />

            {/* <div className="parallax-container">
                <div className="parallax">
                    <img src={`./assets/Fondo1.jpg`}/>
                </div>
            </div>

            <div className="section white" style={{ border: '1px solid blue' }}>
                <div className="container">
                    <h1 className="blue-text">Mantenimientos preventivos y correctivo.</h1>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                
      
                </div>
                    <div class="carousel">
                        <a class="carousel-item" href="#one!"><img src={`./assets/img/calibracion.jpg`}/></a>
                        <a class="carousel-item" href="#two!"><img src={`./assets/img/Mantenimiento.jpg`}/></a>
                        <a class="carousel-item" href="#thr!"><img src={`./assets/img/verificacion.jpg`}/></a>
                    </div>
                </div>

            <div className="parallax-container">
                <div className="parallax">
                    <img src={`./assets/Fondo2.jpg`}/>
                </div>
            </div>
         */}


            <Presentacion />
            <Servicios />
            <Clientes />
            <Contactanos />
            <Footer />
        </>
    )
}
