import shortid from 'shortid';
import firebase from 'firebase/app';
// import 'firebase/firestore';

import { app } from '../config/Firebase';
import { asyncForEach } from '../helpers/Utils';

const DB = app.firestore();
const InformesDB = DB.collection('Informes');
const storage = app.storage();

const tmp_storage = storage.ref('Temp');

export const subirInforme = async(data) =>{
   
    const modificacion = firebase.firestore.FieldValue.serverTimestamp();
    const Configs = DB.collection('ConfigsFacturas');

    const getConfigs = await Configs.doc('XtdxG69aDKUyzBxMcNEM').get().then(snap=>snap.data());
    getConfigs.correlativo_informes += 1;

    const newData = {
        ...data,
        ...getConfigs
    };

    newData.fecha_de_creacion = new Date().toLocaleDateString();
    newData.notificar = true;

    const imgsDespues = [];

    await asyncForEach(newData.img_despues,async({base64})=>{
        const nameFile = shortid.generate();
        const url = await pushDocNew(tmp_storage, base64, nameFile);

        imgsDespues.push({
            nameFile,
            base64:url
        });


    });

    const imgsAntes = [];

    await asyncForEach(newData.img_antes,async({base64})=>{
        const nameFile = shortid.generate();
        const url = await pushDocNew(tmp_storage, base64, nameFile);

        imgsAntes.push({
            nameFile,
            base64:url
        })
    });



    newData.img_antes   = imgsAntes;
    newData.img_despues = imgsDespues;
    newData.modificacion = modificacion;

    return InformesDB.add(newData).then(async()=>{
        return Configs.doc('XtdxG69aDKUyzBxMcNEM').update({
            ...getConfigs,
            correlativo_informes: getConfigs.correlativo_informes,
        })
    });
}

export const actualizarInforme = async(doc_id,data) =>{
    const modificacion = firebase.firestore.FieldValue.serverTimestamp();

    const Configs = DB.collection('ConfigsFacturas');

    const getConfigs = await Configs.doc('XtdxG69aDKUyzBxMcNEM').get().then(snap=>snap.data());

    const newData = {
        ...data,
        ...getConfigs
    };

    const { namePDF="" } = data;

    if(namePDF){
        try {
            const refStorage = storage.ref(`/Informes/Documentos/${data.cliente}`);
            await refStorage.child(namePDF).delete();
            data.namePDF=""
            data.informe_url =""
        } catch (e) {
            console.error('No hay referencia al objecto (Bucket)',e);
            data.namePDF=""
            data.informe_url =""
        }
    }

    newData.fecha_de_creacion = new Date().toLocaleDateString();
    newData.notificar = true;
    const imgsDespues = [];

    await asyncForEach(newData.img_despues,async({base64})=>{
        const nameFile = shortid.generate();
        const url = await pushDoc(tmp_storage, base64, nameFile);
        imgsDespues.push({
            nameFile,
            base64:url
        })
    });

    const imgsAntes = [];

    await asyncForEach(newData.img_antes,async({base64})=>{
        const nameFile = shortid.generate();
        const url = await pushDoc(tmp_storage, base64, nameFile);
        imgsAntes.push({
            nameFile,
            base64:url
        })
    });

    newData.img_antes   = imgsAntes;
    newData.img_despues = imgsDespues;
    newData.modificacion = modificacion;

    return InformesDB.doc(doc_id).update(newData);
}

const isBase64 = (str) =>{
    try {
        return btoa(atob(str)) === str;
    } catch (err) {
        return false;
    }
}

const pushDocNew = async( storage, uri, name ) =>{
    const reference = storage.child(name);
    await reference.putString(uri,'data_url');
    const url = await reference.getDownloadURL();
    return url
}

const pushDoc = async( storage, uri, name ) =>{
    if(!isBase64(uri)){
        return uri;
    }else{
        const reference = storage.child(name);
        await reference.putString(uri,'data_url');
        const url = await reference.getDownloadURL();
        return url
    }
}


