import React from 'react';
import '../../styles/IndexScreen/Contactanos.css';
import ScrollAnimation from 'react-animate-on-scroll';


export const Contactanos = () => {

    return (
        <ScrollAnimation animateIn="fadeIn" duration={2}>
            <div className="contenedor-servicios" style={{ backgroundImage:'url(/assets/servicios-bg.jpg)', paddingTop:'1em' }}>

                <div className="container contacto">
                    <h1 className="center-align title-servicios">Agenda una reunión con nosotros</h1>
                    
                    <div className="container contact-contenedor">

                        <span className="center-align contact-paragraph">Tel. 9978-1178 / 9442-1531</span>
                        <span className="center-align contact-paragraph">ó</span>

                        <button 
                            className="btn center-align waves-effect waves-light btn-cotizar btn-contact modal-trigger"
                            data-target="modal1"
                        >
                            Contáctanos
                        </button>
                    </div>
                </div>
            </div>
        </ScrollAnimation>

    )
}