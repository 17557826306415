import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { Link, useLocation } from 'react-router-dom';
import { useForm } from 'react-hooks-helper';
import { obtenerReporte } from '../../../api/AdministrarReportes/obtenerInfoReporte';

import { FormularioReporte } from '../../ReportesScreen/FormularioReporte';
import '../../../styles/AdministrarReportes/EditarReporte.css';

import swal from 'sweetalert';

import { Loading } from '../../../helpers/Loading';
import { actualizarInforme } from '../../../api/InformesHelpers';

const EditarReporteChild = ({history,data, id_informe}) => {

    const [formulario, setForm]  = useForm(data);

    const [imgAntes, setImgAntes] = useState(data.img_antes);
    const [imgDespues, setImgDespues] = useState(data.img_despues);

    const [loading, setLoading] = useState(false);


    const handleSubmit = async(e) =>{
        e.preventDefault();
        const { 
            cliente="",
            
        } = formulario;
        if(cliente === undefined || formulario.cliente.trim() === ''){
            swal('Ups!!', 'Falta nombre del cliente', 'warning');
            return;
        };
        setLoading(true);


        const data = {
            ...formulario,
            img_antes   : imgAntes,
            img_despues : imgDespues
        }

        try {
            await actualizarInforme(id_informe, data);
            setLoading(false);
            swal('Exito', 'Informe Modificado Correctamente', 'success').then(()=>history.go(0));
        } catch (e) {
            console.error(e);
            swal('ERROR', 'Algo salio mal', 'warning').then(()=>history.go(0));
        }
   
    }

    if(loading){
        return <Loading mensaje="Actualizando Reporte..." />
    }


    return (
        <div>
            <Link 
                className="btn-floating btn-small waves-effect waves-light red btn-Back"
                to="/buscar-reportes"
            >
                <i className="fas fa-arrow-left"/>
            </Link>
            <h1 className="center-align  green-text green-darken-2">Editar Reporte</h1>

            <form onSubmit={handleSubmit}>
            <div className="row">
                
                <div className="contenedor-btn-submit center-align">
                    
                    <button 
                        className="btn waves-effect waves-light center"
                        type="submit"
                    >
                        Subir Informe <i className="fas fa-cloud-upload-alt right" />
                    </button>
                </div>
                <div className="col l12">

                    <FormularioReporte
                        formulario={formulario}
                        setForm={setForm} 
                        setImgAntes={setImgAntes} 
                        setImgDespues={setImgDespues}
                        imgAntes={imgAntes}
                        imgDespues={imgDespues} 
                    />
                   

                </div>

                <br/>
          
            </div>
            </form>
        </div>
    )
}

const DefaultComponent = () =>{

    return(
        <div style={{ height:'90vh', display:'flex', alignItems:'center' }}>

            <h2>Sin ID de referencia</h2>
        </div>
    )
}


export const EditarReporte = ({history}) =>{
    const location = useLocation();

    const { id } = queryString.parse(location.search);


    const [Reporte, setReporte] = useState();

    const [initializing, setInitializing] = useState(true);

    useEffect(() => {
        const getReporte = async() =>{
            const response = await obtenerReporte(id);
            const data = response.data();
        
            setReporte(data)
            setInitializing(false)
        }
        if(id){
            getReporte();
        }

    }, [id]);

    if(!id){
        <DefaultComponent />
        // return;
    }

    if(initializing) return null;

    return <EditarReporteChild history={history} data={Reporte} id_informe={id} />

}