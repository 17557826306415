import firebase from 'firebase/app';
import { app } from '../../config/Firebase';

const DB = app.firestore();
const Facturas =  DB.collection('Facturas');
const storage = app.storage();

export const subirFactura = async( data ) => {
    const modificacion = firebase.firestore.FieldValue.serverTimestamp();
    const Configs = DB.collection('ConfigsFacturas');
    
    if(!data.tipo_factura){
        data.tipo_factura = 'comercial'
    }
    
    const getConfigs = await Configs.doc('XtdxG69aDKUyzBxMcNEM').get().then(snap=>snap.data());

    const newData = {
        ...data,
        ...getConfigs
    }


    newData.fecha_de_creacion = new Date().toLocaleDateString();
    newData.notificar = true;
    newData.modificacion = modificacion;

    return Facturas.add(newData);
}

export const getConfigs = () => DB.collection('ConfigsFacturas').doc('XtdxG69aDKUyzBxMcNEM').get().then(snap=>snap.data());

export const getFactura = async(id) => Facturas.doc(id).get().then(doc=> {
    const {fecha_de_creacion, ...data} = doc.data();

    const res = {
        ...data,
        id: doc.id,
        fecha_de_creacion: typeof fecha_de_creacion === 'object' ? fecha_de_creacion.toDate().toLocaleDateString() : fecha_de_creacion,
    };

    return res;
});

export const setFacturasConfigs = (data) => DB.collection('ConfigsFacturas').doc('XtdxG69aDKUyzBxMcNEM').update(data)

export const getFacturas = async(tipo) =>{
    let data = [];

    await Facturas
    .where('tipo_factura','==',tipo)
    .orderBy('modificacion', 'desc')
    .get()
    .then(docs=>{

        docs.forEach(doc=>{
            const { nombre_cliente, total_pago, fecha, factura_url="", fecha_de_creacion,tipo_factura } = doc.data();


            const datos = {
                nombre_cliente, 
                total_pago, 
                fecha, 
                factura_url, 
                fecha_de_creacion: typeof fecha_de_creacion === 'object'  ? fecha_de_creacion.toDate().toDateString() : fecha_de_creacion,
                id: doc.id,
                tipo_factura,
            }
            data.push(datos)
        })
    })

    return data;
}

export const borrarFactura = async(id) =>{
    const FacturasRef = Facturas.doc(id);
    const FacturaData = await FacturasRef.get();
    
    const { nombre_cliente, name_document="" } = FacturaData.data();
    
    const documentRef = storage.ref(`/Facturas/${nombre_cliente}`);

    try {
        await documentRef.child(name_document).delete();   
    } catch (error) {
        
    }


    return FacturasRef.delete().then(()=>true);

}

export const updateFactura = async(id, data) => {
    console.log('HOLIS');
    const modificacion = firebase.firestore.FieldValue.serverTimestamp();

    const Configs = DB.collection('ConfigsFacturas');
    const getConfigs = await Configs.doc('XtdxG69aDKUyzBxMcNEM').get().then(snap=>snap.data());

    
    const { name_document="" } = data;
    
    if(name_document){
        try {
            const facturaRef = storage.ref(`/Facturas/${data.nombre_cliente}/`);
            await facturaRef.child(name_document).delete();
            data.factura_url = ""
            data.name_document=""
        } catch (e) {
            console.error('No hay referencia a la Factura (Bucket)')
            data.name_document = ""
            data.factura_url = ""
        }
    }


    const newData = Object.assign({},getConfigs,data);
    newData.notificar = true;
    newData.modificacion = modificacion;
    newData.factura_url = '';
    return Facturas.doc(id).update(newData);
}; 



export const searchDocs = async(tipo,busqueda) => {


    let Collection;
    let orderBY;
    switch (tipo) {
        case 'informes':
            orderBY='cliente'
            Collection = DB.collection('Informes');
        break;
        case 'comercial' || 'cotizacion':
            orderBY='nombre_cliente'
            Collection = DB.collection('Facturas');
        break;
    };
    

    const datos = [];

    await Collection.orderBy(orderBY).startAt(busqueda).get().then(docs=>{

        docs.forEach(elem=>{
            
            if(tipo === 'informes'){
                const {cliente,equipo, fecha,document, fecha_de_creacion} = elem.data();
                datos.push({
                    cliente,
                    equipo,
                    fecha,
                    document,
                    fecha_de_creacion,
                    id: elem.id
                }); 
                return datos;
            }else{
                const { nombre_cliente, total_pago, fecha, factura_url="", fecha_de_creacion,tipo_factura } = elem.data();
    
                const factura = {
                    nombre_cliente, 
                    total_pago, 
                    fecha, 
                    factura_url, 
                    fecha_de_creacion: typeof fecha_de_creacion === 'object'  ? fecha_de_creacion.toDate().toDateString() : fecha_de_creacion,
                    id: elem.id,
                    tipo_factura,
                };
                datos.push(factura);
    
            }

        })


    })
    return datos;


}