import React,{ useContext } from 'react'
import { Redirect, Link } from 'react-router-dom';
import { Auth } from '../../auth/AuthContext';
import { app } from '../../config/Firebase';
import swal from 'sweetalert';

import '../../styles/AdminPanel/AdminPanel.css'

export const AdminPanel = ({ history }) => {

    const { user } = useContext(Auth);

    if(!user){
        return <Redirect to="/login" />
    }

    const cerrarSesion = async() =>{
        try {
            await app.auth().signOut();

            swal({
                title: "Sesión Cerrada Exitosamente!",
                icon: "success",
            })

            return <Redirect to="/login" />

        } catch (e) {
            console.error(e);
        }
    }


    return (
        <div className="contenedor-panel">
            <h1 className="center-align blue-text text-darken-2">Bienvenido</h1>
            <div className="container h-100">
                <button 
                    className="btn-floating btn-large waves-effect waves-light red"
                    onClick={()=>cerrarSesion()}
                >
                    <i className="fas fa-sign-out-alt"></i>
                </button>
                <h1 className="center-align green-text text-darken-2">Que tipo de Tarea deseas realizar?</h1>

                <div className="opciones-panel h-100">
                    <div className="btn-panel-contenedor">
                        <Link to="/crear-reportes">
                            <button 
                            className="btn waves-effect waves-light"
                            >Crear Reporte Técnico {' '}
                                <i className="far fa-clipboard"></i>
                            </button>
                        </Link>
                    </div>

                    <div className="btn-panel-contenedor">
                        <Link to="/crear-factura">
                            <button 
                            className="btn waves-effect waves-light red"
                            >Crear Factura {' '}
                                <i className="fas fa-file-invoice"/>
                            </button>
                        </Link>
                    </div>

                    <div className="btn-panel-contenedor">
                        <Link to="/configurar-facturas">
                            <button 
                            className="btn waves-effect waves-light green"
                            >Configurar Facturas {' '}
                                <i className="fas fa-cog"/>
                            </button>
                        </Link>
                    </div>

                    <div className="btn-panel-contenedor">

                        <Link to="/buscar-reportes" className="btn cyan waves-effect waves-light">Buscar Reporte Técnico {' '}
                            <i className="fas fa-search"></i>
                        </Link>

                    </div>
                </div>
            </div>
        </div>
    )
}
