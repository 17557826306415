import React,{ useEffect } from 'react';
import { Link } from 'react-router-dom';
import M from "materialize-css";
import { FadeIn } from '../../helpers/Animaciones';
import '../../styles/UI/Navbar.css';

export const Navbar = () => {

    useEffect(() => {
        const sideNav = document.querySelector('.sidenav');
        M.Sidenav.init(sideNav);
    }, [])

    return (
        <FadeIn>
            <div className="navbar-fixed">
                <nav>
                    <div className="nav-wrapper white navbar">
                        <a href="/" className="brand-logo">
                            <div className="img-logo-container">
                                <img src="./assets/Logo.png" className="img-logo" />
                            </div>
                        </a>
                        <a href="#" data-target="mobile-side" class="sidenav-trigger">
                            <i class="fas fa-bars navbar-icon"></i>
                        </a>
                        <ul id="nav-mobile" className="right hide-on-med-and-down links-container">
                            <li><Link to="/" className="contact">Inicio</Link></li>

                            <li><Link to="/mantenimientos" className="link">Mantenimientos</Link></li>
                            <li><Link to="/capacitaciones" className="contact">Capacitaciones</Link></li>

                            <li><Link to="/aseguramiento" className="link">Aseguramientos</Link></li>
                        </ul>
                    </div>
                </nav>
            </div>

            <ul className="sidenav" id="mobile-side">
                <li><Link to="/" className="contact">Inicio</Link></li>

                <li><Link to="/mantenimientos" className="link">Mantenimientos</Link></li>
                <li><Link to="/capacitaciones" className="contact">Capacitaciones</Link></li>

                <li><Link to="/aseguramiento" className="link">Aseguramientos</Link></li>
            </ul>

        </FadeIn>
    )
}
