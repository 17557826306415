import { app } from '../../config/Firebase';

const DB = app.firestore();
const Informes = DB.collection('Informes');

export const obtenerReportes = async( ) =>{
    const reportes = [];
    await Informes.orderBy('modificacion', 'desc').get().then(querySnapshot=> querySnapshot.forEach(e=>{
        const {cliente,equipo, fecha,informe_url, modificacion} = e.data();
        reportes.push({
            cliente,
            equipo,
            fecha,
            informe_url,
            modificacion: `${modificacion.toDate().toDateString()} || ${modificacion.toDate().toLocaleTimeString()}`,
            id: e.id
        }); 
    }))
    return reportes;
}