import React from 'react'
import { Route, Switch } from 'react-router-dom';
import { AuthContext } from '../auth/AuthContext';
import { AdminPanel } from '../components/AdminPanel/AdminPanel';
import { ConfigFacturas } from '../components/FacturasScreen/ConfigFacturas';
import { FacturasIndex } from '../components/FacturasScreen/FacturasIndex';
import { CrearReportes } from '../components/ReportesScreen/CrearReportes';
import { BuscarReportes } from './BuscarReportes/BuscarReportesMain';

import { MantenimientoPreventivo } from '../components/ServicesRoutes/MantenimientoPreventivo';
import { CapacitacionTecnica } from '../components/ServicesRoutes/CapacitacionTecnica';
import { AseguramientoMetrologico } from '../components/ServicesRoutes/AseguramientoMetrologico';


export const MainRoutesPanel = ({ history }) => {

    return (
        <>
        <AuthContext>
            <Switch>
                    <Route exact path="/admin-panel" component={ AdminPanel } />
                    <Route exact path="/crear-reportes" component={ CrearReportes } />
                    <Route exact path="/crear-factura" component={ FacturasIndex } />
                    <Route exact path="/configurar-facturas" component={ ConfigFacturas } />

                    {/* Rutas Servicios */}

                    <Route exact path="/mantenimientos" component={ MantenimientoPreventivo } />
                    <Route exact path="/capacitaciones" component={ CapacitacionTecnica } />
                    <Route exact path="/aseguramiento" component={ AseguramientoMetrologico } />

                    <Route component={BuscarReportes} />
            </Switch>
        </AuthContext>

        </>
    )
} 
