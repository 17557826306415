import React from 'react';
import '../../styles/UI/Footer.css';

export const Footer = () => {
    return (
        <div className="contenedor-footer">
            <span className="copyrigth">Copyright © 2021 CalyTek</span>

            <div className="redes-sociales">
                <span className="copyrigth">Siguenos en: </span>
                <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/CalyTek.hn"><i className="fab fa-facebook-square icon-footer"></i></a>
            </div>
        </div>
    )
}
