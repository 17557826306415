import React from 'react';
import { FadeIn } from '../../helpers/Animaciones';
import '../../styles/IndexScreen/Presentacion.css';
import ScrollAnimation from 'react-animate-on-scroll';
import { ModalCotizar } from '../UI/ModalCotizar';


export const Presentacion = () => {


    return (
        <>

            <FadeIn>

                <ScrollAnimation animateIn="fadeIn" duration={2}> 
                    <div className="background" style={{ backgroundImage: 'url(/assets/bg.jpg)' }}>

                        <div className="row h-100">

                            <div className="col l6 h-100">
                                <div className="container contenedor-900 h-100">
                                    <div className="contenedor-descripcion h-100">
                                        <h1 className="title-descripcion">Servicios Técnicos, Aseguramiento Metrológico y Capacitaciones,</h1>
                                        <h1 className="m-0 paragraph-title" >para laboratorios industriales, </h1>
                                        <h1 className="m-0 paragraph-title" >control de calidad, producción de alimentos, farmacéuticos,</h1>
                                        <h1 className="m-0 paragraph-title" > clínicos en general.</h1>

                                        <p className="paragraph-descripcion">
                                        Verificación,funcionamiento,ajuste y calibración en equipos de laboratorio para el control de calidad en industrias de alimentos, farmacéutica , clínico hospitalaria.
                                        </p>

                                        <button 
                                            className="btn waves-light btn-cotizar modal-trigger"
                                            data-target="modal1"
                                        >
                                            Contáctanos
                                        </button>

                                    </div>
                                </div>
                            </div>

                            <div className="col l6 h-100 hide-on-med-and-down">
                                <div className="contenedor-img h-100">
                                    <img src={'./assets/imagen-fondo.png'} alt="Imagen fondo" />
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </ScrollAnimation>

            </FadeIn>

            <ModalCotizar />

        </>

    )
}
