import React,{ useEffect } from 'react';
import M from "materialize-css";

import { Navbar } from '../UI/Navbar';

import '../../styles/ServicesRoutes/ServicesStyles.css'
import { Footer } from '../UI/Footer';
import { ModalCotizar } from '../UI/ModalCotizar';

export const MantenimientoPreventivo = () => {

    useEffect(()=>{
        const carousel = document.querySelectorAll('.carousel');
        M.Carousel.init(carousel,{ indicators:true, fullWidth:false });
    },[]);


    return (
        <>
            <Navbar />

            <div
                className="background-services"
                style={{
                    backgroundImage: 'url(./assets/Fondo3.jpg)'
                }}
            >

                <div className="container service__container">

                    <div className="row flex">

                        <div className="col s12 m6 l6 valign-wrapper">
                            <img 
                                src={ './assets/img/mantenimiento/mantenimiento4.jpg' } 
                                alt="Imagen Servicio"
                                className="service__image"
                            />

                            {/* <div className="carousel">
                                <a className="carousel-item" style={{
                                    width:'100%'
                                }} ><img src={`./assets/img/mantenimiento/mantenimiento1.jpg`}/></a>
                                <a className="carousel-item" style={{
                                    width:'100%'
                                }} ><img src={`./assets/img/mantenimiento/mantenimiento2.jpg`}/></a>
                                <a className="carousel-item" style={{
                                    width:'100%'
                                }} ><img src={`./assets/img/mantenimiento/mantenimiento3.jpg`}/></a>
                                <a className="carousel-item" style={{
                                    width:'100%'
                                }} ><img src={`./assets/img/mantenimiento/mantenimiento4.jpg`}/></a>
                            </div> */}

                        </div>

                        <div className="col s12 m6 l6">

                            <h4 className="service__label-title blue-text mb-0">Servicios de Laboratorio</h4>
                            <h5 className="service__label-title blue-text mt-0 blue-text text-darken-1">
                                Mantenimientos Preventivos y Correctivos
                            </h5>
                            <br />
                            <h6 className="service__label-desc">
                                CALYTEK le ofrece el servicio de mantenimiento preventivo entre su amplia gama de servicios para su  laboratorio. 
                            </h6>
                            <br />
                            <ul className="center-align mt-0 label-card-desc list-mantenimiento">
                                <li className="list-services service__label-list green-text" >Revisión y limpieza sistema electrónico. (Revisión de la tarjeta electrónica, voltajes de alimentación, cables, terminales, conectores, teclado, display)</li>
                                <li className="list-services service__label-list green-text" >Limpieza General Interna y Externa .</li>
                                <li className="list-services service__label-list green-text" >Verificación de los mecanismos de ajuste en cada uno de los equipos.</li>
                                <li className="list-services service__label-list green-text" >Verificación y ajuste de funcionamiento del equipo.</li>
                                <li className="list-services service__label-list green-text" >Informe de mantenimiento, sticker y registro fotográfico del trabajo realizado.</li>
                            </ul>
                            <br />
                            <div className="service__label-btn">
                                <button 
                                    className="btn-large center-align blue modal-trigger"
                                    data-target="modal1"
                                >
                                    Cotizar
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <Footer />

            <ModalCotizar />
        </>
    )
}
