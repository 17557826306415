import React, { useEffect, useState, useRef} from 'react';
import '../../styles/ReportesScreen/FormularioReportes.css';
import M from "materialize-css";
import { ImgCards } from './ImgCards';

import shortid from 'shortid';
import { getBase64 } from '../../helpers/Utils';
import { getAllClients } from '../../api/Clientes/Clientes';

import { Hint } from 'react-autocomplete-hint';

export const FormularioReporte = ({formulario,setForm, setImgAntes, setImgDespues, imgAntes, imgDespues}) => {
    
    const {
        servicio,
        fecha, 
        ficha,

        cliente='',
        direccion,
        ubicacion,
        contacto,
        
        equipo, 
        modelo, 
        marca, 
        numero_serie, 
        
        falla_reportada,
       
        inspeccion_visual,
        pruebas_iniciales,
        limpieza_general,
        verificacion_componentes,
        pruebas_funcionamiento,
        ajuste_estandar,
        cond_ambientales,
        acciones_correctivas,
        cambio_respuestos,
        equipo_operativo,

        actividades_adicionales,
        estado_servicio,
        comentarios,
        elementos_utilizados,

        horas_trabajo,

    } = formulario;

    const [clientesData, setClientesData] = useState([]);
    const [ clientsAutoReportComplete, setClientsAutoReportComplete ] = useState([]);


    useEffect(()=>{
        var elems = document.querySelectorAll('.datepicker');
        M.Datepicker.init(elems,{
            defaultDate: new Date(),
            onSelect: (date)=>{
                const e = {
                    target:{
                        name:'fecha',
                        type:'text',
                        value: date.toLocaleDateString()
                    }
                }
                setForm(e)
            }
        });

  
        var selects = document.querySelectorAll('select');
        M.FormSelect.init(selects);

        const getClientsNames = async() =>{
            const response = await getAllClients();

            let names = [];

            let clientes = [];

            response.forEach(e => {
                const { cliente } = e.data();
                names.push(cliente);
                clientes.push({ ...e.data() })
            });
            setClientesData(clientes)

            return names

        }


        getClientsNames()
        .then(names=>{
            let data = [];

            names.forEach(name=> {
                data.push(name)
            } );

            setClientsAutoReportComplete(data)
        })
    },[]);

    const handleAddImgAntes = async({target}) =>{

        const base64 = await getBase64(target.files[0]);

        setImgAntes(state=>[ ...state, 
            {
                id: shortid.generate(),
                base64
            }
        ]);
    }

    const handleAddImgDespues = async({target}) =>{
        const base64 = await getBase64(target.files[0]);


        setImgDespues(state=>[ ...state, 
            {
                id: shortid.generate(),
                base64
            }
        ]);
    }

    const handleDeleteImagenes = (tipo, idfile) =>{
        console.log(tipo);
        if(tipo === 'antes'){
            console.log('Aqui');
            setImgAntes(state=> state.filter( ({id}) => id !== idfile )  );
        }else{
            setImgDespues(state=> state.filter( ({id}) => id !== idfile )  );
        }

    }

    const direccionRef = useRef(null);
    const contactoRef  = useRef(null);
    const clienteRef   = useRef(null);

    const autoCompleteInputs = (clienteVerify) =>{
        if(!clienteVerify) return;

        const res = clientesData.find(e=> {
            return e.cliente === clienteVerify;
        });

        if(!res) return;
       
        const { direccion="", contacto="", cliente="" } = res ;

        clienteRef.current.value = cliente
        direccionRef.current.value = direccion;
        contactoRef.current.value = contacto;

        const input_client = {
            target:{
                name:'cliente',
                type:'text',
                value: cliente
            }
        }
        setForm(input_client);

        const input_dir = {
            target:{
                name:'direccion',
                type:'text',
                value: direccion
            }
        }
        setForm(input_dir)

        const input_contacto = {
            target:{
                name:'contacto',
                type:'text',
                value: contacto
            }
        }
        setForm(input_contacto)
    }


    return (
        // <form onSubmit={(e)=> e.preventDefault()}>
        <div className="row form-reportes">
            <p className="green-text col m12 text-darken-1">Detalles del Informe:</p>


            <div className="input-field col l4 m4 s6">
                <select name="servicio" defaultValue={servicio} onChange={setForm} required>
                    <option value="Preventivo">Preventivo</option>
                    <option value="Correctivo">Correctivo</option>
                    <option value="Diagnostico">Diagnostico</option>
                    <option value="Visita técnica">Visita técnica</option>

                </select>
                <label>Tipo de Servicio:</label>
            </div>

            <div className="input-field col l4 m4 s6">
                <input 
                    type="text" 
                    id="fecha_servicio" 
                    className="datepicker"
                    name="fecha"
                    defaultValue={fecha}
                />

                <label htmlFor="fecha_servicio">Fecha Servicio: </label>
            </div>
            <div className="input-field col l4 m4 s6">

                <input 
                    id="ficha" 
                    type="text"
                    name="ficha"
                    defaultValue={ficha}
                    onChange={setForm}
                    
                />
                <label htmlFor="cliente">Ficha:</label>

            </div>

            <p className="green-text col m12 text-darken-1">Informacion de Cliente:</p>


            <div className="input-field col l3 m6 s6">

                <Hint 
                    options={clientsAutoReportComplete} 
                    allowTabFill
                    onFill={
                        value=>{
                            autoCompleteInputs(value);
                        }
                    }
                >
                    <input
                        autoComplete="off"
                        id="cliente"
                        type="text"
                        name="cliente" 
                        value={formulario.cliente}
                        onChange={setForm}
                        ref={clienteRef}
                    />
                </Hint>


                {/* <input 
                    id="cliente" 
                    type="text"
                    name="cliente"
                    defaultValue={cliente}
                    onBlur={(e)=> {
                        if (e.currentTarget === e.target) {
                            autoCompleteInputs(e.currentTarget.value);
                        } 
                    }}
                    ref={clienteRef}
                    onChange={setForm}
                    className="autocomplete"
                    autoComplete="off"
                    required
                />
                <label htmlFor="cliente">Cliente: *</label> */}
                { cliente && <span className="helper-text hide-on-small-only">Este campo no se puede modificar</span> }
            </div>


            <div className="input-field col l3 m6 s6">
                <input 
                    id="direccion" 
                    type="text"
                    name="direccion"
                    defaultValue={direccion}
                    onChange={setForm}
                    ref={direccionRef}
                    required  
                />
                <label htmlFor="direccion">Dirección:</label>
            </div>

            
            <div className="input-field col l3 m6 s6">
                <input 
                    id="ubicacion" 
                    type="text"
                    name="ubicacion"
                    defaultValue={ubicacion}
                    onChange={setForm}
                    required  
                />
                <label htmlFor="ubicacion">Ubicación</label>
            </div>

            <div className="input-field col l3 m6 s6">
                <input 
                    id="contacto" 
                    type="text"
                    name="contacto"
                    defaultValue={contacto}
                    onChange={setForm}
                    ref={contactoRef}
                    required 
                />
                <label htmlFor="contacto">Contacto:</label>
            </div>

            <p className="green-text col m12 text-darken-1">Detalles Equipo:</p>
            
            <div className="input-field col l3 m6 s6">
                <input 
                    id="nombre_equipo" 
                    type="text"
                    name="equipo"
                    defaultValue={equipo}
                    onChange={setForm}
                    required  
                />
                <label htmlFor="nombre_equipo">Equipo:</label>
            </div>

            <div className="input-field col l3 m6 s6">
                <input 
                    id="modelo" 
                    type="text"
                    name="modelo"
                    defaultValue={modelo}
                    onChange={setForm}
                    required  
                />
                <label htmlFor="modelo">Modelo:</label>
            </div>

            <div className="input-field col l3 m6 s6">
                <input 
                    id="marca" 
                    type="text"
                    name="marca"
                    defaultValue={marca}
                    onChange={setForm}
                    required  
                />
                <label htmlFor="marca">Marca:</label>
            </div>

            <div className="input-field col l3 m6 s6">
                <input 
                    id="numero_serie" 
                    type="text"
                    name="numero_serie"
                    defaultValue={numero_serie}
                    onChange={setForm}
                    required  
                />
                <label htmlFor="numero_serie">Numero de Serie:</label>
            </div>

            <p className="green-text col m12 text-darken-1">Info. Inspección:</p>

            <div className="input-field col l12 m12 s12">
                <textarea 
                    id="falla" 
                    className="materialize-textarea"
                    name="falla_reportada"
                    defaultValue={falla_reportada}
                    onChange={setForm} 
                ></textarea>
                <label htmlFor="falla">Falla Reportada:</label>
            </div>

            
            <div className="col l12 m12 s12">
                <blockquote>
                    Actividades realizadas
                </blockquote>

                <div className="col l6 m6 s12">

                <div className="input-field col s12">
                    <select name="inspeccion_visual" defaultValue={inspeccion_visual} onChange={(e)=>{

                        setForm({
                            // ...e,
                            target:{
                                name:'inspeccion_visual',
                                value: (e.target.value === 'true') ? true : (e.target.value === 'false') ? false : e.target.value
                            }
                        })
                    }}>
                        <option value="true">Cumple</option>
                        <option value="false">Fallo</option>
                        <option value="N/A">N/A</option>
                    </select>
                    <label>Inspección visual del dispositivo</label>
                </div>

                <div className="input-field col s12">
                    <select name="pruebas_iniciales" defaultValue={pruebas_iniciales} onChange={e=>{
                        setForm({
                            // ...e,
                            target:{
                                name:'pruebas_iniciales',
                                value: (e.target.value === 'true') ? true : (e.target.value === 'false') ? false : e.target.value
                            }
                        })
                    }}>
                        <option value="true">Cumple</option>
                        <option value="false">Fallo</option>
                        <option value="N/A">N/A</option>
                    </select>
                    <label>Pruebas iniciales de operación y funcionamiento</label>
                </div>

                <div className="input-field col s12">
                    <select name="limpieza_general" defaultValue={limpieza_general} onChange={e=>{
                        setForm({
                            // ...e,
                            target:{
                                name:'limpieza_general',
                                value: (e.target.value === 'true') ? true : (e.target.value === 'false') ? false : e.target.value
                            }
                        })
                    }}>
                        <option value="true">Cumple</option>
                        <option value="false">Fallo</option>
                        <option value="N/A">N/A</option>
                    </select>
                    <label>Limpieza general (interna y externa) </label>
                </div>

                <div className="input-field col s12">
                    <select name="verificacion_componentes" defaultValue={verificacion_componentes} onChange={e=>{
                         setForm({
                            // ...e,verificacion_componentes
                            target:{
                                name:'verificacion_componentes',
                                value: (e.target.value === 'true') ? true : (e.target.value === 'false') ? false : e.target.value
                            }
                        })
                    }}>
                        <option value="true">Cumple</option>
                        <option value="false">Fallo</option>
                        <option value="N/A">N/A</option>
                    </select>
                    <label>Verificación de componentes electrónicos</label>
                </div>

                <div className="input-field col s12">
                    <select name="pruebas_funcionamiento" defaultValue={pruebas_funcionamiento} onChange={e=>{
                         setForm({
                            // ...e,verificacion_componentes
                            target:{
                                name:'pruebas_funcionamiento',
                                value: (e.target.value === 'true') ? true : (e.target.value === 'false') ? false : e.target.value
                            }
                        })
                    }}>
                        <option value="true">Cumple</option>
                        <option value="false">Fallo</option>
                        <option value="N/A">N/A</option>
                    </select>
                    <label>Pruebas de funcionamiento</label>
                </div>
    
       
                </div>

                <div className="col l6 m6 s12">

                <div className="input-field col s12">
                    <select name="ajuste_estandar" defaultValue={ajuste_estandar} onChange={e=>{
                        setForm({
                            // ...e,verificacion_componentes
                            target:{
                                name:'ajuste_estandar',
                                value: (e.target.value === 'true') ? true : (e.target.value === 'false') ? false : e.target.value
                            }
                        })
                    }}>
                        <option value="true">Cumple</option>
                        <option value="false">Fallo</option>
                        <option value="N/A">N/A</option>
                    </select>
                    <label>Ajuste estándar y aseguramiento metrológico</label>
                </div>

                <div className="input-field col s12">
                    <select name="cond_ambientales" defaultValue={cond_ambientales} onChange={e=>{
                        setForm({
                            // ...e,verificacion_componentes
                            target:{
                                name:'cond_ambientales',
                                value: (e.target.value === 'true') ? true : (e.target.value === 'false') ? false : e.target.value
                            }
                        })
                    }}>
                        <option value="true">Cumple</option>
                        <option value="false">Fallo</option>
                        <option value="N/A">N/A</option>
                    </select>
                    <label>Condiciones ambientales</label>
                </div>

                <div className="input-field col s12">
                    <select name="acciones_correctivas" defaultValue={acciones_correctivas} onChange={e=>{
                        setForm({
                            // ...e,verificacion_componentes
                            target:{
                                name:'acciones_correctivas',
                                value: (e.target.value === 'true') ? true : (e.target.value === 'false') ? false : e.target.value
                            }
                        })
                    }}>
                        <option value="true">Cumple</option>
                        <option value="false">Fallo</option>
                        <option value="N/A">N/A</option>
                    </select>
                    <label>Acciones correctivas aplicadas</label>
                </div>

                <div className="input-field col s12">
                    <select name="cambio_respuestos" defaultValue={cambio_respuestos} onChange={e=>{
                       setForm({
                            // ...e,verificacion_componentes
                            target:{
                                name:'cambio_respuestos',
                                value: (e.target.value === 'true') ? true : (e.target.value === 'false') ? false : e.target.value
                            }
                        })
                    }}>
                        <option value="true">Cumple</option>
                        <option value="false">Fallo</option>
                        <option value="N/A">N/A</option>
                    </select>
                    <label>Cambio de repuestos</label>
                </div>

                <div className="input-field col s12">
                    <select name="equipo_operativo" defaultValue={equipo_operativo} onChange={e=>{
                        setForm({
                            // ...e,verificacion_componentes
                            target:{
                                name:'equipo_operativo',
                                value: (e.target.value === 'true') ? true : (e.target.value === 'false') ? false : e.target.value
                            }
                        })
                    }}>
                        <option value="true">Cumple</option>
                        <option value="false">Fallo</option>
                        <option value="N/A">N/A</option>
                    </select>
                    <label>Equipo en calidad de operación</label>
                </div>

        
                </div>
                
            </div>

            <div className="col l12 m12 s12">
                <div className="input-field col l6 m6 s12">
                    <textarea 
                        id="otras-actividades" 
                        className="materialize-textarea"
                        name="actividades_adicionales"
                        onChange={setForm}
                        defaultValue={actividades_adicionales} 
                    ></textarea>
                    <label htmlFor="otras-actividades">Actividades adicionales:</label>
                </div>

                <div className="input-field col l6 m6 s12">
                        <select name="estado_servicio" defaultValue={estado_servicio} onChange={setForm}>
                            <option value="Concluido">Concluido</option>
                            <option value="Pendiente">Pendiente</option>
                        </select>
                        <label>Estado del servicio</label>
                </div>

            </div>
      

            
            <div className="input-field col l4 m4 s12">
                <textarea 
                    id="comentarios" 
                    className="materialize-textarea"
                    name="comentarios"
                    defaultValue={comentarios}
                    onChange={setForm} 
                ></textarea>
                <label htmlFor="comentarios">Comentarios:</label>
            </div>

            <div className="input-field col l4 m4 s12">
                <textarea 
                    id="elementos" 
                    className="materialize-textarea"
                    name="elementos_utilizados"
                    defaultValue={elementos_utilizados}
                    onChange={setForm} 
                ></textarea>
                <label htmlFor="elementos">Elementos Utilizados:</label>
            </div>

            <div className="input-field col l4 m4 s12">

                <input 
                    id="horas" 
                    className="materialize-textarea"
                    name="horas_trabajo"
                    defaultValue={horas_trabajo}
                    onChange={setForm}
                    type="number" 
                />
                <label htmlFor="horas">Horas Trabajadas:</label>
            </div>

            <p className="center-align col m12">Inspección Visual</p>

            <div className="row">
                <div className="col l6 m6 s6">
                    <p className="center-align titles-btn">Antes</p>

                    <div className="btn-contenedor center-align">
                        <input accept="image/*" id="img_antes" type="file" multiple style={{display:'none'}} onChange={handleAddImgAntes} />
                        <label htmlFor="img_antes" className="btn center-align waves-effect waves-light">Agregar <i className="fas fa-images"></i></label>
                    </div>

                    <div style={{
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'center',
                        justifyContent:'center',
                        marginTop:'1.5em'
                    }}>
                    {
                        imgAntes.map(data=>(
                            <ImgCards key={data.id} tipo="antes" {...data} handleDeleteImagenes={handleDeleteImagenes} />
                        ))
                    }
                        
                      
                    </div>


                </div>

                <div className="col l6 m6 s6">
                    <p className="center-align titles-btn">Despues</p>

                    <div className="btn-contenedor center-align">
                        <input accept="image/*" id="img_despues" type="file" multiple style={{display:'none'}} onChange={handleAddImgDespues} />

                        <label htmlFor="img_despues" className="btn red center-align waves-effect waves-light">Agregar <i className="far fa-image"></i></label>
                    </div>

                    <div style={{
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'center',
                        justifyContent:'center',
                        marginTop:'1.5em'
                    }}>
                    {
                        imgDespues.map(data=>(
                            <ImgCards key={data.id} tipo="despues" {...data} handleDeleteImagenes={handleDeleteImagenes} />
                        ))
                    }
                        

                      
                    </div>


                </div>

            </div>

            <br />
    </div>
    // </form>

    )
}
