import React from 'react';

export const Loading = () => {
    return (
        <div className="contenedor-spinner-data" style={{ height:'90vh' }}>
            <i className="fas center-align blue-text fa-spinner fa-spin spinner-data" />
            <label className="blue-text" style={{ margin:'1em', fontSize:'1.2em' }} >Cargando...</label>
        </div>
    )
}
