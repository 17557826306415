import React,{ useEffect } from 'react';
import M from "materialize-css";

import { Navbar } from '../UI/Navbar';

import '../../styles/ServicesRoutes/ServicesStyles.css'
import { Footer } from '../UI/Footer';
import { ModalCotizar } from '../UI/ModalCotizar';

export const CapacitacionTecnica = () => {

    useEffect(()=>{
        const carousel = document.querySelectorAll('.carousel');
        M.Carousel.init(carousel,{ indicators:true, fullWidth:false });
    },[]);


    return (
        <>
            <Navbar />

            <div
                className="background-services"
                style={{
                    backgroundImage: 'url(./assets/Fondo3.jpg)'
                }}
            >

                <div className="container service__container">

                    <div className="row">

                        <div className="col s12 m6 l6">
                            <img 
                                src={ './assets/img/capacitaciones/capacitacion1.jpg' } 
                                alt="Imagen Servicio"
                                className="service__image"
                            />

                        </div>

                        <div className="col s12 m6 l6">

                            <h4 className="service__label-title blue-text mb-0">Servicios de Laboratorio</h4>
                            <h5 className="service__label-title blue-text mt-0 blue-text text-darken-1">
                                Capacitación Técnica Equipos de Laboratorio de calidad y laboratorios clínicos.
                            </h5>
                            <br />
                            <h6 className="service__label-desc">
                            CALYTEK le ofrece el servicio de Capacitaciones entre su amplia gama de servicios para su laboratorio, el aprendizaje y formación constante son la clave para asegurar la calidad de los procesos y elevar la productividad.
                            </h6>
                            <br />
                            <ul className="center-align mt-0 label-card-desc list-mantenimiento">
                                <li className="service__label-list" >Buenas prácticas de laboratorio para:</li>
                                <li className="list-services service__label-list green-text" >Potenciómetros</li>
                                <li className="list-services service__label-list green-text" >Peso</li>
                                <li className="list-services service__label-list green-text" >Volumen</li>
                                <li className="list-services service__label-list green-text" >Espectrofotometría</li>
                                <li className="list-services service__label-list green-text" >Analizadores químicos</li>

                            </ul>
                            <br />
                            <div className="service__label-btn">
                                <button 
                                    className="btn-large center-align blue modal-trigger"
                                    data-target="modal1"
                                >
                                    Cotizar
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <Footer />

            <ModalCotizar />
        </>
    )
}
