import React,{ useEffect,useState } from 'react';
import M from "materialize-css";
import { useForm } from 'react-hooks-helper';

import axios from 'axios';
import swal from 'sweetalert';

export const ModalCotizar = () => {

    const [ formContacto, setFormContacto ] = useForm({});
    const [closeModal, setCloseModal] = useState(false);


    useEffect(()=>{

        const modal = document.querySelector('.modal');
        const instances = M.Modal.init(modal);
        setCloseModal(instances)

    },[]);

    const handleSubmit = async(e) =>{
        e.preventDefault();

        const data = {
            service_id: 'service_xvw76el',
            template_id: 'template_6z9mahd',
            user_id: 'user_hTCRp3jGQT7JQm9kMfOff',
            template_params: {
                destinatarios   : 'calytek.hn@gmail.com',
                nombres         : formContacto.nombres,
                correo          : formContacto.correo,
                telefono        : formContacto.telefono,
                asunto          : formContacto.asunto,
            }
        };

        try {
            await axios.post('https://api.emailjs.com/api/v1.0/email/send', JSON.stringify(data) ,{
                headers:{
                    'Content-Type': 'application/json'
                }
            });
            closeModal.close()
            swal("Excelente!", "Se ha registrado la solicitud; en breve te contactaremos", "success");
        } catch (e) {
            console.error(e);
        }
    }


    return (
        <div id="modal1" className="modal modal-contacto">
            <form onSubmit={handleSubmit} autoComplete="on">
                <div className="modal-content">
                    <h5 className="center-align title-modal"  >Contáctanos</h5>
                    <hr className="line-contacto center-align"/>

                    
                    <div className="row form-contacto" >
                        <div className="input-field col s12 m12 l12">
                            <i className="fas fa-user prefix"/>
                            <input
                                name="nombres"
                                onChange={setFormContacto} 
                                placeholder="Nombre y Apellido *" 
                                type="text"
                                autoComplete="on" 
                                required
                            />
                        </div>

                        <div className="input-field col s12 m6 l6">
                            <i className="fas fa-envelope prefix"/>
                            <input
                                name="correo"
                                onChange={setFormContacto} 
                                placeholder="Correo *" 
                                type="email"
                                autoComplete="on"
                                required
                            />
                        </div>

                        <div className="input-field col s12 m6 l6">
                            <i className="fas fa-phone prefix"/>
                            <input
                                name="telefono"
                                onChange={setFormContacto} 
                                placeholder="Telefono" 
                                type="text"
                                autoComplete="on"
                            />
                        </div>

                        <div className="input-field col s12 m12 l12">
                            <textarea 
                                name="asunto"
                                onChange={setFormContacto}
                                id="textarea1" 
                                className="materialize-textarea"
                            ></textarea>
                            <label htmlFor="textarea1">Déjanos un mensaje....</label>
                        </div>

                    </div>

                </div>

                <div className="modal-footer">
                    <button className="btn waves-effect waves-light btn-submit-contact" type="submit">Enviar
                        <i className="fas fa-paper-plane right"/>
                    </button>
                </div>
            </form>
        </div>
    )
}
