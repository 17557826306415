import React from 'react';
import '../../styles/IndexScreen/Clientes.css';
import ScrollAnimation from 'react-animate-on-scroll';

export const Clientes = () => {
    return (
        <ScrollAnimation animateIn="fadeIn" duration={2}>
            <div>
                <h1 className="center-align title-servicios">Nuestros Clientes</h1>
                <hr className="line" />

                <div className="container">

                    <div className="clientes-contenedor">
                        <img className="img-cliente" src={'./assets/Portafolio/cargil.png'}  alt="Cliente" />
                        <img className="img-cliente" src={'./assets/Portafolio/agrinvet.png'}  alt="Cliente" />
                        <img className="img-cliente" src={'./assets/Portafolio/engasa.png'}  alt="Cliente" />
                        <img className="img-cliente" src={'./assets/Portafolio/laboratorios_medicos.png'}  alt="Cliente" />
                    </div>

                </div>
            </div>
        </ScrollAnimation>
    )
}
