import React, { useContext, useState } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { Auth } from '../../auth/AuthContext';

import { FormularioReporte } from './FormularioReporte';
import { useForm } from 'react-hooks-helper';
import { subirInforme } from '../../api/InformesHelpers';

import swal from 'sweetalert';
import { Loading } from '../../helpers/Loading';




export const CrearReportes = ({history}) => {

    const { user } = useContext(Auth);
    const [imgAntes, setImgAntes] = useState([]);
    const [imgDespues, setImgDespues] = useState([]);
    const [loading, setLoading] = useState(false);


    const [formulario, setForm] = useForm({

        servicio:'Preventivo',
        estado_servicio:'Concluido',
        inspeccion_visual:true,
        pruebas_iniciales:true,
        limpieza_general:true,
        verificacion_componentes:true,
        pruebas_funcionamiento:true,
        ajuste_estandar:true,
        cond_ambientales:true,
        acciones_correctivas:true,
        cambio_respuestos:true,
        equipo_operativo:true,

    });
    
    if(!user) return <Redirect to="/login" />;

    const handleSubmit = async(e) =>{
        e.preventDefault();
        const {  cliente="", fecha="" } = formulario;
        if(cliente === undefined || cliente.trim() === ''){
            swal('Ups!!', 'Falta nombre del cliente', 'warning');
            return;
        }

        if(fecha === undefined || fecha.trim() === ''){
            swal('Ups!!', 'Agregar fecha', 'warning');
            return;
        }
        setLoading(true);

        const data = {
            ...formulario,
            img_antes   : imgAntes,
            img_despues : imgDespues
        }

        setTimeout(()=>{
            setLoading(false);
        }, 15000)

        try {
            await subirInforme(data);
            setLoading(false);
            swal('Exito', 'Informe Agregado', 'success').then(()=>history.go(0));
        } catch (e) {

            swal('ERROR', 'Algo Fallo', 'warning');
            console.error(e);
        }
    }

    if(loading){
        return <Loading mensaje="Creando Reporte..." />
    }
    

    return (
        <div>
            <Link 
                className="btn-floating btn-small waves-effect waves-light red btn-goBack"
                to="/admin-panel"
            >
                <i className="fas fa-arrow-left"/>
            </Link>
            <h1 className="center-align  blue-text text-darken-2">Crear Reporte</h1>

            <form onSubmit={handleSubmit}>
            <div className="row">
                
                <div className="contenedor-btn-submit center-align">
                    
                    <button 
                        className="btn waves-effect waves-light center"
                        type="submit"
                    >
                        Subir Informe <i className="fas fa-cloud-upload-alt right" />
                    </button>
                </div>
                <div className="col l12">

                    <FormularioReporte
                        formulario={formulario}
                        setForm={setForm} 
                        setImgAntes={setImgAntes} 
                        setImgDespues={setImgDespues}
                        imgAntes={imgAntes}
                        imgDespues={imgDespues} 
                    />
                   

                </div>

                <br/>
          
            </div>
            </form>
        </div>
    )
}
