import React, { useEffect, useState } from 'react';
import { app } from '../config/Firebase';

export const Auth = React.createContext();


export const AuthContext = ({ children }) => {

    
    const [user, setUser] = useState(app.auth().currentUser);
    const [initializing, setInitializing] = useState(true);

    const AuthStateChanged = (user) =>{
        if(!user) setUser(null);
        setUser(user);
        setInitializing(false)
    }
 
    useEffect(()=>{
        app.auth().onAuthStateChanged(AuthStateChanged);
    },[]);

    if(initializing) return null;

    return (
        <Auth.Provider
            value={{
               user,
            }}
        >
            {children}
        </Auth.Provider>
    )
}
