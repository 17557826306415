import React from 'react'

export const ImgCards = ({base64, id,tipo, handleDeleteImagenes}) => {




    return (
        <div className="img-contenedor" style={{ width:'50%', position:'relative' }}>
            <span
                style={{
                    position:'absolute',
                    zIndex: 2,
                    color:'red',
                    fontSize:'2em',
                    right:0,
                    cursor:'pointer',
                    marginRight:'.5em'
                }}
                onClick={()=> handleDeleteImagenes(tipo,id) }
            >&times;</span>
            <img 
                className="responsive-img img-prueba center-align w-100" 
                alt="Imagen Inspeccion Visual"
                src={base64}
                style={{ borderRadius:'15px' }} 
            />
        </div>
    )
}
