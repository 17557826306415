import React, { useState, useRef } from 'react';
import { app } from '../../config/Firebase';
import '../../styles/LoginScreen/Login.css';

export const Login = ({ history }) => {


    const [correo, setCorreo] = useState('');
    const [contrasena, setContrasena] = useState('');

    const inputCorreoRef = useRef();
    const inputPasswordRef = useRef();

    const handleSubmit = async() =>{
        if(correo.trim() === '' ) return inputCorreoRef.current.classList.add('invalid');
        if(contrasena.trim() === '') return inputPasswordRef.current.classList.add('invalid');

        try {
            await app.auth().signInWithEmailAndPassword(correo,contrasena);
            return history.replace('/admin-panel');
        } catch (e) {
            console.error(e);
        }

    }


    return (
        <div className="container contenedor-login">

            <div className="row h-100">

                <div className="container contenedor-form h-100">

                    <div className="row formulario">

                        <h1 className="center-align title-login blue-text text-darken-2">Identificate</h1>

                        <div className="input-field col s12">
                            <input 
                                placeholder="correo@correo.com" 
                                id="first_name" 
                                type="text" 
                                className="validate"
                                ref={inputCorreoRef}
                                value={correo}
                                onChange={(e)=> setCorreo(e.target.value)}
                            />
                            <label htmlFor="first_name">Correo</label>
                            <span className="helper-text" data-error="Este campo es obligatorio"></span>
                        </div>

                        <div className="input-field col s12">
                            <input 
                                id="password" 
                                type="password" 
                                className="validate"
                                ref={inputPasswordRef}
                                value={contrasena}
                                onChange={(e)=>setContrasena(e.target.value)} 
                            />
                            <label htmlFor="password">Contraseña</label>
                            <span className="helper-text" data-error="Este campo es obligatorio"></span>
                        </div>

                        <div className="col s12 btn-login-contenedor">
                            <button 
                                className="btn center-align waves-effect waves-light"
                                onClick={()=> handleSubmit()}
                            > 
                                Ingresar
                            </button>
                        </div>

                    </div>
                </div>
            </div>
            
        </div>
    )
}
