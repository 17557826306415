import { app } from '../../config/Firebase';

const DB = app.firestore();
const Informes = DB.collection('Informes');

export const obtenerReporte = async(id) => await Informes.doc(id).get(); 

export const buscarReporte  = async(consulta) =>{
  
    return Informes.orderBy('cliente').startAt(consulta.busqueda).get();
}