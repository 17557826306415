import { app } from '../../config/Firebase';


const storage = app.storage();
const DB = app.firestore();
const Informes = DB.collection('Informes');

export const borrarInforme = async(id) =>{
    const informeRef = Informes.doc(id);
    const informe = await informeRef.get();

    const { cliente, namePDF="" } = informe.data();

    const documentRef = storage.ref(`/Informes/Documentos/${cliente}`);

    if(namePDF){
        await documentRef.child(namePDF).delete();
    }
    return informeRef.delete().then(()=>true)

}
