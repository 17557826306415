import React from 'react';
import { useHistory } from 'react-router-dom';
import '../../styles/AdministrarReportes/CardReporte.css';
import swal from 'sweetalert';
import { borrarFactura } from '../../api/Facturas/FacturaHelpers';
import { parseToMoney } from '../../helpers/parseNumbers';


export const CardFactura = ({
    nombre_cliente,
    total_pago,
    fecha,
    fecha_de_creacion,
    factura_url,
    id
}) => {
    const history = useHistory();

    const handleEditar = () =>{
        history.push(`/editar-factura?id=${id}`);
    }

    const handleDelete = () =>{
        swal({
            title: "Estas Seguro?",
            text: "Una vez borrado el informe no se podrá recuperar",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then(async(Borrar) => {
            if (Borrar) {
                try {
                    await borrarFactura(id);
                    swal({
                        title: "Excelente",
                        text: "La Factura fue borrado sastisfactoriamente",
                        icon: "success",
                    });
                    history.go(0)
                } catch (e) {
                    console.error(e);
                }
            } 
        });

    }

    return (

        
        <div className="contenedor-card-informe">
            <div className="row card-informe">
                
                <div className="col l2 m2 s12">
                    <h6 className="center-align m-0" >{nombre_cliente}</h6>
                </div>
                <div className="col l2 m2 s12">
                    <h6 className="center-align">L. { parseToMoney(total_pago) }</h6>
                </div>
                <div className="col l4 m4 s12">
                    <p className="center-align m-0">Fecha Factura:</p>
                    <p className="center-align m-0">{fecha}</p>
                </div>
                <div className="col l4 m4 s12">
                    <div className="btns-contenedor">
                        <button className="waves-effect blue waves-light btn-small" onClick={()=>handleEditar()}>
                            <i className="fas fa-edit"/>
                        </button>
                        {
                            factura_url ? (
                                <a  href={factura_url} 
                                className="waves-effect green waves-light btn-small" target="_blank"
                                rel="noreferrer"
                                >
                                    <i className="fas fa-file-download"/>
                                </a>
                            ) : (
                                'N/A'
                            )
                        }
                        <button onClick={()=>handleDelete()} className="waves-effect red waves-light btn-small">
                            <i className="fas fa-trash" />
                        </button>
                    </div>
                </div>
            </div>
            <p className="right-align label-elaborado grey-text">{fecha_de_creacion}</p>
        </div>
    )
};
