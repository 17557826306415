import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/database'

const appConfig = {
    apiKey: "AIzaSyAnjrwD4Z2WTdAK6wLvq2hlGycuL9e31LQ",
    authDomain: "calytekhn-19e80.firebaseapp.com",
    databaseURL: "https://calytekhn-19e80.firebaseio.com",
    projectId: "calytekhn-19e80",
    storageBucket: "calytekhn-19e80.appspot.com",
    messagingSenderId: "90433105475",
    appId: "1:90433105475:web:b2cc593154b7e32bb06ef6",
    measurementId: "G-PBCMQWJN9X"
}

export const app = firebase.initializeApp(appConfig);
