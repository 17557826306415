import React from 'react';
import '../styles/ReportesScreen/Loading.css';

export const Loading = ({mensaje}) => {
    return (
        <div className="loading-contenedor">
            <i className="fas fa-spinner fa-spin spinner-loading"/>
            <h5 className="label-loading">{mensaje}</h5>
        </div>  
    )
}
