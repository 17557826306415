import React, {useEffect, useState} from 'react';
import M from "materialize-css";
import LazyLoad from 'react-lazyload';
import { obtenerReportes } from '../../api/AdministrarReportes/ObtenerReportes';

import '../../styles/AdministrarReportes/SpinnerData.css';
import { CardInforme } from './CardInforme';
import { Link } from 'react-router-dom';
import { getFacturas, searchDocs } from '../../api/Facturas/FacturaHelpers';
import { CardFactura } from './CardFactura';
import { getAllClients } from '../../api/Clientes/Clientes';

export const BuscarReporte = () => {


    const [loading, setLoading] = useState(true);
    const [ tipoDocumento, setTipoDocumento ] = useState('informes');
    const [busqueda, setBusqueda] = useState('');
    const [clientes, setClientes] = useState([]);

    const[ data, setData ] = useState([]);



    useEffect(()=>{

        const selects = document.querySelectorAll('select');
        M.FormSelect.init(selects)


        const tomarDatos = async() =>{
            setData([]);
            const datos = await obtenerReportes();
            setData(datos);
            setLoading(false)
        };

        const tomarFacturas = async() =>{
            setData([]);
            const datos = await getFacturas(tipoDocumento);
            setData(datos);
            setLoading(false)
        }

        switch(tipoDocumento){
            case 'informes':
                tomarDatos();
            break;
            case 'comercial':
                tomarFacturas();
            break;
            case 'cotizacion':
                tomarFacturas();
            break;
            default:
                tomarDatos();
        }

    },[tipoDocumento]);


    useEffect(() => {

        const getClientsNames = async() =>{
            const response = await getAllClients();
            let names = [];
            response.forEach(e => {
                const { cliente } = e.data();
                setClientes([...clientes, e.data() ]);
                names.push(cliente)
            });

            return names
        };

        getClientsNames()
        .then(names=>{
            let object = {};
            names.forEach(name=>{
                object[name] = null
            });
            return object;
        }).then(res=>{
            const elems = document.querySelectorAll('.autocomplete');
            const instances = M.Autocomplete.init(elems, {
                data: res
            });
        })

    }, [])

    const handleBuscar = async() =>{
        setLoading(true);
        setData([]);
        try {
            const content =  await searchDocs(tipoDocumento,busqueda);
            setData(content);
            setLoading(false);
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    }

    const autoCompleteInputs = (clienteVerify) =>{
        if(!clienteVerify) return;

        const res = clientes.find(e=> e.cliente === clienteVerify);
        if(!res || res.length > 1) return;
       
        const { cliente="" } = res;

        setBusqueda(cliente);

    }
    



    return (
        <div className="container">
            <h1 className="center-align indigo-text">Buscar Reportes</h1>
            <Link to="/admin-panel" className="btn-floating btn-small waves-effect waves-light red"><i className="fas fa-arrow-left"/></Link>
            <div className="row">
                <div className="input-field col l5 m5 s12">
                    <i className="fas fa-search prefix indigo-text"/>
                    <input 
                        id="buscar" 
                        className="autocomplete"
                        type="text"
                        name="busqueda"
                        onChange={ ({target})=>setBusqueda(target.value) }
                        onBlur={(e)=> {
                            if (e.currentTarget === e.target) {
                                autoCompleteInputs(e.currentTarget.value);
                            } 
                        }}
                        autoComplete="off"
                    />
                    <label htmlFor="buscar">Buscar Cliente</label>
                </div>
                <div className="input-field col l5 m5 s12">
                    <select 
                        defaultValue="informes"  
                        onChange={({target})=> setTipoDocumento(target.value) }
                    >
                        
                        <option value="informes">Informes</option>
                        <option value="comercial">Facturas</option>
                        <option value="cotizacion">Cotizaciones</option>
                    </select>
                    <label>Elegir Documento</label>
                </div>
                <div className="btn-search-contenedor">
                    <button
                        onClick={()=>handleBuscar()}
                        className="btn-small indigo waves-effect  waves-light col l2 m2"
                    >Buscar</button>
                </div>
            </div>
            <div style={{ padding: '0em 2em' }}>
                <h6 className="center-align">ULTIMOS AGREGADOS</h6>

                {
                    loading && (
                        <div className="contenedor-spinner-data">
                            <i className="fas center-align blue-text fa-spinner fa-spin spinner-data" />
                        </div>
                    )
                }

                {
                    data.length === 0 && !loading ? (
                        <div className="contenedor-spinner-data">
                            <h5 className="red-text">No hay Reportes</h5>
                        </div>
                    ): null
                }

                <LazyLoad height={200}>
                    {
                        <SelectCards tipo={tipoDocumento} data={data}  />
                    }
                </LazyLoad>

            </div>
        </div>
    )
}

const SelectCards = ({tipo,data}) =>{

    switch (tipo) {
        case 'informes':
           return data.map(elem=>(
                <CardInforme { ...elem } key={elem.id} />
            ));
        case 'comercial':
            return data.map(elem=>(
                <CardFactura { ...elem } key={elem.id} />
            ));
        case 'cotizacion':
            return data.map(elem=>(
                <CardFactura { ...elem } key={elem.id} />
            ));
        default:
            return data.map(elem=>(
                <CardInforme { ...elem } key={elem.id} />
            ));
    }

}
