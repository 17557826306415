import React,{ useEffect } from 'react';
import M from "materialize-css";
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';

import '../../styles/IndexScreen/Servicios.css';


export const Servicios = () => {

    useEffect(()=>{
        const carousel = document.querySelectorAll('.carousel');
        M.Carousel.init(carousel,{ indicators:true, fullWidth:false });
    },[]);


    return (
        <ScrollAnimation animateIn="fadeIn" duration={2}>
            <div className="row contenedor-servicios" style={{
                backgroundImage:'url(/assets/servicios-bg.jpg)'
            }}>

                <div className="col l12">
                    <h1 className="center-align title-servicios">Nuestros Servicios</h1>
                    <hr className="line" />

                    <p className="center-align paragraph-servicios">Servicios técnicos en equipos de laboratorio clínicos e industriales.</p>

                    <div className="container">
                    
                    <div className="Card row">
                        {/* <div 
                            className="col l6 m6 img-servicio hide-on-small-only" 
                            style={{ backgroundImage:'url(/assets/img/Mantenimiento.jpg)' }}
                        /> */}

                        <div className="col s12 l6 m6 img-servicio valign-wrapper"> 
                            <div className="carousel hide-on-small-only">
                                <a className="carousel-item"><img src={`./assets/img/mantenimiento/mantenimiento1.jpg`}/></a>
                                <a className="carousel-item"><img src={`./assets/img/mantenimiento/mantenimiento2.jpg`}/></a>
                                <a className="carousel-item"><img src={`./assets/img/mantenimiento/mantenimiento3.jpg`}/></a>
                                <a className="carousel-item"><img src={`./assets/img/mantenimiento/mantenimiento4.jpg`}/></a>
                            </div>

                            <div className="show-on-small hide-on-med-and-up hide-on-large-only">
                                <img 
                                    className="mobile-image" 
                                    src={`./assets/img/mantenimiento/mantenimiento1.jpg`} 
                                    alt="Imagen Mantenimiento" 
                                />
                            </div>
                        </div>
                        

                        <div className="col s12 l6 m6">
                            <div className="h-100 service-container">

                                <div className="title-card-container" style={{ paddingBottom:'.5em' }}>
                                    <i className="fas fa-tools icon-card hide-on-small-only"/>
                                    <p className="center-align label-card mb-0">Mantenimientos preventivos y correctivo.</p>
                                    
                                </div>

                                    <p className="center-align label-card-desc hide-on-small-only mt-0">
                                        CALYTEK le ofrece el servicio de mantenimiento preventivo entre su amplia gama de servicios para su  laboratorio. 
                                    </p>

                                    <ul className="center-align mt-0 label-card-desc list-mantenimiento hide-on-small-only">
                                        <li className="list-services" >Autoclaves</li>
                                        <li className="list-services" >Incubadoras</li>
                                        <li className="list-services" >Hornos</li>
                                        <li className="list-services" >Potenciómetros de pH y conductividad</li>
                                        <li className="list-services" >Balanzas</li>
                                        <li className="list-services" >Destiladores agua</li>
                                        <li className="list-services" >Destiladores Kjeldahl ( sistemas completos )</li>
                                    </ul>

                                <Link to="/mantenimientos" className="anclabtn">
                                    <button
                                        type="button" 
                                        className="btn-service waves-light green-text"
                                    >Conocé mas...</button>

                                </Link>
                    
                            </div>
                        </div>
                    </div>

                    <div className="Card row Card-textLong">

                        <div className="col s12 l6 m6 img-servicio valign-wrapper show-on-small hide-on-med-and-up hide-on-large-only"> 

                            {/* <div className="show-on-small hide-on-med-and-up hide-on-large-only"> */}
                                <img 
                                    className="mobile-image" 
                                    src={`./assets/img/capacitaciones/capacitacion1.jpg`} 
                                    alt="Imagen Mantenimiento" 
                                />
                            {/* </div> */}
                        </div>
                        <div className="col s12 l6 m6">
                            <div className="h-100 service-container">
                                

                                <div className="title-card-container">
                                    <i className="fas fa-chalkboard-teacher icon-card hide-on-small-only"/>
                                    <p className="center-align label-card mb-0">Capacitación Técnica Equipos de Laboratorio de calidad y laboratorios clínicos. </p>
                                    
                                </div>

                                <p className="center-align mt-0 label-card-desc hide-on-small-only">
                                    CALYTEK le ofrece el servicio de Capacitaciones  entre su amplia gama de servicios para su  laboratorio, el aprendizaje y formación constante son la clave para asegurar la calidad de  los procesos y elevar la productividad.     
                                </p>

                                <ul className="center-align mt-0 label-card-desc list-mantenimiento hide-on-small-only">
                                    <li>Buenas prácticas de laboratorio para:</li>
                                    <li className="list-services-especial m-0">Potenciómetros</li>
                                    <li className="list-services-especial m-0">Peso</li>
                                    <li className="list-services-especial m-0">Volumen</li>
                                    <li className="list-services-especial m-0">Espectrofotometría</li>
                                    <li className="list-services-especial m-0">Analizadores químicos</li>
                                </ul>

                                <Link to="/capacitaciones" className="anclabtn">
                                    <button
                                        type="button" 
                                        className="btn-service waves-light green-text"
                                    >Conocé mas...</button>

                                </Link>
                    
                            </div>
                        </div>

                        <div 
                            className="col s12 l6 m6 img-servicio hide-on-small-only" 
                            style={{ backgroundImage:'url(/assets/img/capacitaciones/capacitacion1.jpg)' }}
                        />

                        {/* <div className="col l6 m6 img-servicio hide-on-small-only valign-wrapper h-100"> 
                            <div className="carousel">
                                <a className="carousel-item"><img src={`./assets/img/capacitaciones/capacitacion1.jpg`}/></a>
                            </div>
                        </div> */}

                        </div>

                    <div className="Card row">
                        {/* <div 
                            className="col l6 m6 img-servicio hide-on-small-only" 
                            style={{ backgroundImage:'url(/assets/img/calibracion.jpg)' }}
                        /> */}

                        <div className="col l6 m6 img-servicio valign-wrapper"> 
                            <div className="carousel hide-on-small-only">
                                <a className="carousel-item"><img src={`./assets/img/aseguramientos/aseguramiento1.jpg`}/></a>
                                <a className="carousel-item"><img src={`./assets/img/aseguramientos/aseguramiento2.jpg`}/></a>
                                <a className="carousel-item"><img src={`./assets/img/aseguramientos/aseguramiento3.jpg`}/></a>
                            </div>

                            <div className="show-on-small hide-on-med-and-up hide-on-large-only">
                                <img 
                                    className="mobile-image" 
                                    src={`./assets/img/aseguramientos/aseguramiento1.jpg`} 
                                    alt="Imagen Mantenimiento" 
                                />
                            </div>
                        </div>
                        

                        <div className="col l6 m6">
                            <div className="h-100 service-container">

                                <div className="title-card-container">
                                    <i className="fas fa-balance-scale icon-card hide-on-small-only"/>
                                    <p className="center-align label-card mb-0">Aseguramiento Metrológico</p>
                                    
                                </div>

                                <p className="center-align  label-card-desc hide-on-small-only">
                                    En CALYTEK, le ofrece el servicio de calibración  entre su amplia gama de servicios para laboratorio.
                                </p>


                                <ul className="center-align mt-0 label-card-desc list-mantenimiento hide-on-small-only">
                                    <li className="list-services">Servicio de calibración trazable al sistema internacional de unidades.</li>
                                    <li className="list-services">Copia de trazabilidad de los patrones utilizados y pasantías de los metrologos que realizan las mediciones.</li>
                                    <li className="list-services">Informe de calibración con estimación de incertidumbre según la GUM.</li>
                                    <li className="list-services">Sticker de calibración.</li>
                                </ul>

                                <Link to="/aseguramiento" className="anclabtn">
                                    <button
                                        type="button" 
                                        className="btn-service waves-light green-text"
                                    >Conocé mas...</button>
                                </Link>

                            </div>
                        </div>
                    </div>

                    </div>
                </div>
            </div>
        </ScrollAnimation>
    )
}
