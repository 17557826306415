import React,{ useState,useEffect } from 'react';
import M from "materialize-css";
import '../../styles/FacturasScreen/FacturasIndex.css';

import { AddCargo } from './AddCargo';
import shortid from 'shortid';
import { parseToMoney } from '../../helpers/parseNumbers';
import { useForm } from 'react-hooks-helper';
import { subirFactura, updateFactura } from '../../api/Facturas/FacturaHelpers';
import { Loading } from '../UI/Loading';

import swal from 'sweetalert';
import { Redirect } from 'react-router-dom';
import { getAllClients } from '../../api/Clientes/Clientes';

import { Hint } from 'react-autocomplete-hint';

export const FacturasIndex = ({ data, history }) => {

    const [ redirect, setRedirect ] = useState(false);

    const [ loading, setLoading ] = useState(false);

    const [clientesData, setClientesData] = useState([]);

    const [ clientsAutoComplete, setClientsAutoComplete ] = useState([]);

    const [ formulario, setFormulario ] = useForm(
    data ? data :
    {
        cai:'',
        cargos:'',
        tipo_factura:'comercial',
        codigo_cliente:'',
        correo:'',
        direccion_1:'',
        direccion_2:'',
        direccion_cliente:'',
        fecha:'',
        fecha_de_creacion:'',
        fecha_limite_emision:'',
        isv_total:'',
        nombre_cliente:'',
        numero_factura:'000-001-01-000000',
        rango_autorizado:'',
        rtn:'',
        rtn_cliente:'',
        telefono:'',
        tipo_factura:'',
        total_cargos:'',
        total_pago:'',

        comentarios:'',
        num_orden_exento:'N/A',
        termino_pago:'N/A',
        vendedor:'N/A',
        moneda:'N/A',
        registro_exonerado:'N/A',
        registro_sag:'N/A',

        cot_correlativo:''
    });

    useEffect(()=>{
        const datepickers = document.querySelectorAll('.datepicker');
        const selects = document.querySelectorAll('select');
        M.Datepicker.init(datepickers,{
            defaultDate: new Date(),
            onSelect: (date)=>{
                const e = {
                    target:{
                        name:'fecha',
                        type:'text',
                        value: date.toLocaleDateString()
                    }
                }
                setFormulario(e)
            }
        });
        M.FormSelect.init(selects);

        
        const getClientsNames = async() =>{
            const response = await getAllClients();

            let names = [];
            let clientes = [];
            response.forEach(e => {
                const { cliente } = e.data();
                names.push(cliente);
                clientes.push({ ...e.data() })
            });
            setClientesData(clientes)

            return names

        }

        getClientsNames()
        .then(names=>{
            let data = [];

            names.forEach(name=> {
                data.push(name)
            } );

            setClientsAutoComplete(data)
        })

    },[]);


    const [ cargos, setCargos ] = useState( data ? data.cargos : []);

    const handleAddCargo = () =>{
        
        setCargos([...cargos,{
            id: shortid.generate(),
            codigo_producto:'',
            cantidad: 1,
            cargo_descripcion:'',
            precio_unidad: '',
            descuentos:'',
            total: 0
        }])
    }

    let total_cargos = cargos.map(cargo=>cargo.total).reduce((a, b) => a + b, 0);
    let isv_cargos = formulario.registro_exonerado === 'N/A' ? total_cargos * 0.15 : 0; 

    const handleBack = () => setRedirect(true)

    const handleSubmit = async(e) =>{
        e.preventDefault();

        const{ nombre_cliente="", fecha="" } = formulario;

        if(nombre_cliente.trim() === '' || fecha.trim()==='' ){
            swal("Advertencia","Nombre del cliente y fecha es requerido","warning");
            return;
        }
    
        const data = {
            ...formulario,
            cargos: cargos,
            total_cargos:total_cargos,
            isv_total: isv_cargos,
            total_pago: isv_cargos + total_cargos
        }
        try {
            if(data.id){
                const { id, ...content } = data;
                await updateFactura(id, content)
            }else{
                await subirFactura(data)
            }
            setLoading(false);
            swal("Factura Ingresada","Se ha ingresado una nueva factura","success");
            history.go(0);
        } catch (e) {
            console.error(e);
        }
    }

    const disableEnterSubmit = (e) => {
        const code = e.keyCode || e.which;
        if (code === 13) {
            e.preventDefault();
            return false;
        }
    }


    const autoCompleteInputs = (clienteVerify) =>{
        if(!clienteVerify) return;

        const res = clientesData.find(e=> e.cliente === clienteVerify);
        if(!res || res.length > 1) return;
       
        const { direccion="", cliente="", rtn="",codigo_cliente="" } = res;
        // direccionRef.current.value  = direccion;
        // contactoRef.current.value   = contacto;
        // clienteRef.current.value    = cliente;
        // rtnRef.current.value        = rtn;
        // codigoRef.current.value     = codigo_cliente;

        const input_direccion = {
            target:{
                name:'direccion_cliente',
                type:'text',
                value: direccion
            }
        };
        setFormulario(input_direccion)

        const input_client = {
            target:{
                name:'nombre_cliente',
                type:'text',
                value: cliente
            }
        };
        setFormulario(input_client)

        const rtn_client = {
            target:{
                name:'rtn_cliente',
                type:'text',
                value: parseInt(rtn,10)
            }
        };
        setFormulario(rtn_client);

        const input_cod = {
            target:{
                name:'codigo_cliente',
                type:'text',
                value: codigo_cliente
            }
        };
        setFormulario(input_cod)
    }


    if(redirect) return <Redirect to="/admin-panel" />

    if(loading) return <Loading />
    
    return (
      <form
        onSubmit={handleSubmit}
        className="formulario-facturas"
        onKeyPress={disableEnterSubmit}
      >
        <button
          type="button"
          onClick={() => handleBack()}
          className="btn-floating btn-small waves-effect waves-light red fab-goBack"
        >
          <i className="fas fa-arrow-left" />
        </button>

        <button
          type="submit"
          className="btn-floating btn-large waves-effect waves-light green fab-submit-btn"
        >
          <i className="fas fa-save" />
        </button>

        {data ? (
          <h1 className="center-align green-text">Editar Factura</h1>
        ) : (
          <h1 className="center-align red-text">Crear Factura</h1>
        )}

        <div className="contenedor-facturas row">
          <p className="green-text col s12">Detalles Factura</p>

          <div className="input-field col s3 l3 m4">
            <input
              placeholder="000-001-00000063"
              id="numero_factura"
              type="text"
              name="numero_factura"
              onChange={setFormulario}
              value={formulario.numero_factura}
              required
            />
            <label htmlFor="numero_factura">Numero de Factura</label>
          </div>

          <div className="input-field col s3 l3 m4">
            <input
              type="text"
              id="fecha_factura"
              className="datepicker"
              name="fecha"
            />
            <label htmlFor="fecha_factura">Fecha</label>
          </div>

          <div className="input-field col s3 l3 m4">
            <select
              name="tipo_factura"
              onChange={setFormulario}
              value={formulario.tipo_factura}
            >
              <option value="comercial">Comercial</option>
              <option value="cotizacion">Cotización</option>
            </select>
            <label>Tipo Factura</label>
          </div>

          {formulario.tipo_factura === "cotizacion" && (
            <>
              <div className="input-field col s12 l8 m8">
                <input
                  id="comentarios"
                  type="text"
                  name="comentarios"
                  onChange={setFormulario}
                  value={formulario.comentarios}
                  required
                />
                <label htmlFor="comentarios">Comentarios:</label>
              </div>

              <div className="input-field col s12 l4 m4">
                <input
                  id="cot_correlativo"
                  type="text"
                  name="cot_correlativo"
                  onChange={setFormulario}
                  value={formulario.cot_correlativo}
                  required
                />
                <label htmlFor="cot_correlativo">Correlativo Cotizacion:</label>
              </div>
            </>
          )}

          <p className="green-text col s12">Detalles Cliente</p>

          <div className="input-field col s3 l3 m6">


            <Hint 
                options={clientsAutoComplete} 
                allowTabFill
                onFill={
                    value=>{
                        autoCompleteInputs(value);
                    }
                }
            >
              <input
                autoComplete="off"
                id="cliente"
                type="text"
                name="nombre_cliente" 
                value={formulario.nombre_cliente}
                onChange={setFormulario}
              />
            </Hint>
          </div>

          <div className="input-field col s3 l3 m6">
            <input
              placeholder="Col. Lomas del Guijarro"
              id="direccion"
              type="text"
              name="direccion_cliente"
              onChange={setFormulario}
              value={formulario.direccion_cliente}
              required
            />
            <label htmlFor="direccion">Dirección:</label>
          </div>

          <div className="input-field col s3 l3 m6">
            <input
              id="cod_cliente"
              type="text"
              name="codigo_cliente"
              onChange={setFormulario}
              value={formulario.codigo_cliente}
              required
            />
            <label htmlFor="cod_cliente">Codigo del Cliente:</label>
          </div>

          <div className="input-field col s3 l3 m6">
            <input
              id="rtn_cliente"
              name="rtn_cliente"
              type="number"
              onChange={setFormulario}
              value={formulario.rtn_cliente}
              required
            />
            <label htmlFor="rtn_cliente">R.T.N. del Cliente:</label>
          </div>

          <div className="input-field col s2 l2 m4">
            <input
              id="num_exento"
              type="text"
              name="num_orden_exento"
              onChange={setFormulario}
              value={formulario.num_orden_exento}
              required
            />
            <label htmlFor="num_exento">N° Orden Compra Exenta:</label>
          </div>

          <div className="input-field col s2 l2 m4">
            <input
              id="term_pago"
              type="text"
              value={formulario.termino_pago}
              name="termino_pago"
              onChange={setFormulario}
              required
            />
            <label htmlFor="term_pago">Término de pago:</label>
          </div>

          <div className="input-field col s2 l2 m4">
            <input
              id="vendedor"
              type="text"
              name="vendedor"
              onChange={setFormulario}
              value={formulario.vendedor}
              required
            />
            <label htmlFor="vendedor">Vendedor:</label>
          </div>

          <div className="input-field col s2 l2 m4">
            <input
              id="moneda"
              type="text"
              value={formulario.moneda}
              name="moneda"
              onChange={setFormulario}
              required
            />
            <label htmlFor="moneda">Moneda:</label>
          </div>

          <div className="input-field col s2 l2 m4">
            <input
              id="reg_exonerado"
              type="text"
              value={formulario.registro_exonerado}
              name="registro_exonerado"
              onChange={setFormulario}
              required
            />
            <label htmlFor="reg_exonerado">Registro Exonerado N°:</label>
          </div>

          <div className="input-field col s2 l2 m4">
            <input
              id="reg_sag"
              type="text"
              value={formulario.registro_sag}
              name="registro_sag"
              onChange={setFormulario}
              required
            />
            <label htmlFor="reg_sag">Registro SAG N°:</label>
          </div>

          <p className="green-text col s12">Cargos:</p>

          <div className="col s12">
            <button
              type="button"
              className="waves-effect waves-light btn"
              onClick={() => handleAddCargo()}
            >
              <i className="fas fa-plus" />
            </button>
          </div>

          <div className="col s12">
            {cargos.map((cargo) => (
              <AddCargo key={cargo.id} setCargos={setCargos} {...cargo} />
            ))}
          </div>

          {cargos.length > 0 && (
            <div className="col s12">
              <p className="price_total">
                Total sin I.S.V: L. {parseToMoney(total_cargos)}
              </p>

              <p className="price_total">
                I.S.V: L. {parseToMoney(isv_cargos)}
              </p>

              <p className="price_total">
                TOTAL: L. {parseToMoney(isv_cargos + total_cargos)}
              </p>
            </div>
          )}
        </div>
      </form>
    );
}
