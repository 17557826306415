import React, { useEffect } from 'react';

export const AddCargo = ({
    id,
    codigo_producto,
    cantidad,
    cargo_descripcion,
    precio_unidad,
    descuentos,
    total,
    setCargos
}) => {

    const handleDelete = (id) =>{
        setCargos(state=>(
            state.filter(cargo=> cargo.id !== id )
        ));;
    }
    let total_price = (cantidad * precio_unidad) - descuentos;

    useEffect(()=>{

        setCargos(state=>(
            state.map(cargo=>{
                if(cargo.id === id){
                    return {
                        ...cargo,
                        total: total_price
                    }
                }
                return cargo;
            })
        ));

    },[total_price])


    return (
      <div className="col s12">
            <div className="input-field col s1 m3">
                <input 
                    type="text" 
                    value={codigo_producto}
                    onChange={({target})=>{

                        setCargos(state=>(
                            state.map(cargo=>{
                                if(cargo.id === id){
                                    return {
                                        ...cargo,
                                        codigo_producto: target.value
                                    }
                                }

                                return cargo;
                            })
                        ))
                    }} 
                />
                <label>Cód:</label>
            </div>

            <div className="input-field col s1 m3">
                <input 
                    type="number" 
                    value={cantidad}
                    min="1"
                    onChange={({target})=>{
                        setCargos(state=>(
                            state.map(cargo=>{
                                if(cargo.id === id){
                                    return {
                                        ...cargo,
                                        cantidad: target.value
                                    }
                                }
                                return cargo;
                            })
                        ))
                    }}
                />
                <label>Cantidad:</label>
            </div>

            <div className="input-field col s3 m3">
                <input 
                    type="text" 
                    value={cargo_descripcion}
                    onChange={({target})=>{
                        setCargos(state=>(
                            state.map(cargo=>{
                                if(cargo.id === id){
                                    return {
                                        ...cargo,
                                        cargo_descripcion: target.value
                                    }
                                }
                                return cargo;
                            })
                        ))
                    }}
                />
                <label>Descripción:</label>
            </div>

            <div className="input-field col s2 m3">
                <input 
                    type="number" 
                    step="0.01" 
                    value={precio_unidad}
                    onChange={({target})=>{
                        setCargos(state=>(
                            state.map(cargo=>{
                                if(cargo.id === id){
                                    return {
                                        ...cargo,
                                        precio_unidad: target.value
                                    }
                                }
                                return cargo;
                            })
                        ))
                    }}
                />
                <label>Precio unidad:</label>
            </div>

            <div className="input-field col s2 m3">
                <input 
                    type="number" 
                    step="0.01" 
                    value={descuentos} 
                    onChange={({target})=>{
                        setCargos(state=>(
                            state.map(cargo=>{
                                if(cargo.id === id){
                                    return {
                                        ...cargo,
                                        descuentos: target.value
                                    }
                                }
                                return cargo;
                            })
                        ))
                    }}
                />
                <label>Des. y Rebajas:</label>
            </div>

            <div className="input-field col s2 m3">
                <input 
                    // type="number" 
                    value={ total }
                    disabled={true}               
                />
                {/* <label>Total:</label> */}
            </div>

            <div className="col s1 m6" style={{ display:'flex', justifyContent:'flex-end', alignItems:'center', marginTop: '.5em' }}>

                <button 
                    className="waves-effect waves-light btn-small red"
                    onClick={()=>handleDelete(id)}
                >
                    <i className="fas fa-trash"/>
                </button>
            </div>
      </div>
    );
}
