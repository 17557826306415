import React,{ useEffect, useState, useLayoutEffect } from 'react'
import M from "materialize-css";
import { getConfigs, setFacturasConfigs } from '../../api/Facturas/FacturaHelpers';
import { Loading } from '../UI/Loading';
import swal from 'sweetalert';

import '../../styles/FacturasScreen/ConfigsFactura.css';
import { useForm } from 'react-hooks-helper';
import { addNewClient, deleteClient, getAllClients } from '../../api/Clientes/Clientes';

export const ConfigFacturas = ({history}) => {

    const [configs, setConfigs] = useState();
    const [clientes, setClientes] = useState([]);

    const [ form_client, setForm_client ] = useForm({});
    
    const [ loading, setLoading ] = useState(true);

    const [showInputClient, setShowInputClient] = useState(false);

    const toggleInputClient = ( ) => {
        setShowInputClient( !showInputClient );
    }


    useEffect(()=>{

        const setStateConfigs = async() =>{
            const data = await getConfigs();
            setConfigs(data);
            setLoading(false);

            const datepickers = document.querySelectorAll('.datepicker');
            M.Datepicker.init(datepickers,{
                defaultDate: new Date(),
                onSelect: (date)=>{
                    setConfigs({
                        ...configs,
                        fecha_limite_emision: date.toLocaleDateString()
                    })
                }
            });

        }
        
        setStateConfigs();

        const getClients = async()=>{
            const data = await getAllClients();
            let response = [];

            data.forEach(e=>{
                const elem = e.data();
                elem.id = e.id
                response.push(elem)
            });

            setClientes(response)
        }

        getClients();

    },[]);


    const handleBack = () => history.goBack();

    const handleSubmitConfigs = async(e) =>{
        e.preventDefault();
        setLoading(true)

        try {
           await setFacturasConfigs(configs);
           setLoading(false)

           swal('Informe','Configuraciones actualizadas','success')
        } catch (e) {
            console.error(e);
            swal('Error','Algo Salio Mal','danger');
        }

    }

    const handleSubmitClient = async(e) =>{
        e.preventDefault();

        try {
            await addNewClient(form_client)
            e.target.reset();
            toggleInputClient();
            history.go(0)
        } catch (e) {
            console.error(e);
        }
    }

    const handleDeleteClient = async(idClient) =>{

        swal({
            title: "Estas Seguro?",
            text: "Una vez borrado no se podra recuperar",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async(acepto)=>{
            
            if(acepto){
                setClientes( clientes.filter( ({id}) => id !== idClient) );
                
                try {
                    await deleteClient(idClient);
                } catch (e) {
                    console.error(e);
                }
                        
            }
        })

    } 

    if(loading) return <Loading />


    return (
        <>
        <button
            type="button"
            onClick={()=> handleBack()} 
            className="btn-floating btn-large waves-light red fab-goBack"
        >
            <i className="fas fa-arrow-left"/>
        </button>
        <form 
            className="container"
            onSubmit={handleSubmitConfigs}
        >


            <h1 className="center-align blue-text">Configurar Facturas</h1>

            <div className="row">
                <p className="green-text col s12">Data Facturas</p>

                <div className="input-field col s6">
                    <input 
                        id="direccion_1" 
                        type="text" 
                        name="direccion_1"
                        defaultValue={configs.direccion_1}
                        onChange={({target})=>{
                            setConfigs({
                                ...configs,
                                direccion_1:target.value
                            })
                        }}
                        required
                    />
                    <label htmlFor="direccion_1">Dirección 1:</label>
                </div>

                <div className="input-field col s6">
                    <input 
                        id="direccion_12" 
                        type="text" 
                        name="direccion_2"
                        defaultValue={configs.direccion_2}
                        onChange={({target})=>{
                            setConfigs({
                                ...configs,
                                direccion_2:target.value
                            })
                        }}
                        required
                    />
                    <label htmlFor="direccion_2">Dirección 2:</label>
                </div>

                <div className="input-field col s4">
                    <input 
                        id="telefono" 
                        type="text" 
                        name="telefono"
                        defaultValue={configs.telefono}

                        onChange={({target})=>{
                            setConfigs({
                                ...configs,
                                telefono:target.value
                            })
                        }}
                        required
                    />
                    <label htmlFor="telefono">Telefono:</label>
                </div>

                <div className="input-field col s4">
                    <input 
                        id="correo" 
                        type="text" 
                        name="correo"
                        defaultValue={configs.correo}
                        onChange={({target})=>{
                            setConfigs({
                                ...configs,
                                correo: target.value
                            })
                        }}
                        required
                    />
                    <label htmlFor="telefono">Correo:</label>
                </div>


                <div className="input-field col s4">
                    <input 
                        id="rtn" 
                        type="text" 
                        name="rtn"
                        defaultValue={configs.rtn}
                        onChange={({target})=>{
                            setConfigs({
                                ...configs,
                                rtn:target.value
                            })
                        }}
                        required
                    />
                    <label htmlFor="rtn">RTN:</label>
                </div>

                <div className="input-field col s4">
                    <input 
                        id="cai" 
                        type="text" 
                        name="cai"
                        defaultValue={configs.cai}
                        onChange={({target})=>{
                            setConfigs({
                                ...configs,
                                cai:target.value
                            })
                        }}
                        required
                    />
                    <label htmlFor="cai">C.A.I:</label>
                </div>

                <div className="input-field col s4">
                    <input 
                        type="text" 
                        id="fecha_limite" 
                        className="datepicker"
                        name="fecha_limite_emision"
                    />
                    <label htmlFor="fecha_limite">Fecha limite de emisión</label>
                </div>

                <div className="input-field col s4">
                    <input 
                        id="rango" 
                        type="text" 
                        name="rango_autorizado"
                        defaultValue={configs.rango_autorizado}
                        onChange={({target})=>{
                            setConfigs({
                                ...configs,
                                rango_autorizado:target.value
                            })
                        }}
                        required
                    />
                    <label htmlFor="rango">Rango Autorizado:</label>
                </div>

                <button className="btn waves-light" type="submit" name="action">
                    Guardar
                </button>


            </div>

        </form>
        
        

        <div  style={{ position:'relative' }}>
            <h4 className="center-align green-text">Clientes</h4>

            <button 
                className="btn-floating btn-large waves-light red fab-add-cliente"
                onClick={ ()=> toggleInputClient() }
            >
                <i className="fas fa-user-plus"></i>
            </button>

            <div className="row">

                <div className="col l12 m12 s12">

                    {
                        clientes.map((e)=>(
                            <CardClient key={e.id} {...e} deleteClient={handleDeleteClient} />
                        ))
                    }

                    <div className="col l12 m12 s12 card-input" style={{ display: showInputClient ? 'block' : 'none' }}>

                        <form onSubmit={handleSubmitClient}>
                        <div className="input-field col l2 m2 s12">
                            <input 
                                id="cliente-form" 
                                type="text" 
                                required
                                name="cliente"
                                onChange={setForm_client}
                            />
                            <label htmlFor="cliente-form">Cliente</label>
                        </div>

                        <div className="input-field col l2 m2 s12">
                            <input 
                                id="direccion-form" 
                                type="text" 
                                required
                                name="direccion"
                                onChange={setForm_client}
                            />
                            <label htmlFor="direccion-form">Dirección</label>
                        </div>

                        <div className="input-field col l2 m2 s12">
                            <input 
                                id="contacto-form" 
                                type="text" 
                                required
                                name="contacto"
                                onChange={setForm_client}
                            />
                            <label htmlFor="contacto-form">Contacto</label>
                        </div>

                        <div className="input-field col l2 m2 s12">
                            <input 
                                id="rtn-form" 
                                type="text" 
                                required
                                name="rtn"
                                onChange={setForm_client}
                            />
                            <label htmlFor="rtn-form">RTN</label>
                        </div>

                        <div className="input-field col l2 m2 s12">
                            <input 
                                id="rtn-form" 
                                type="text" 
                                required
                                name="codigo_cliente"
                                onChange={setForm_client}
                            />
                            <label htmlFor="rtn-form">Codigo Cliente</label>
                        </div>




                        <div className="col l2 m2 center-align">
                            
                            <div className="buttons-client">
                                <button 
                                    className="waves-light btn-small blue"
                                    type="submit"
                                ><i className="fas fa-save"></i></button>
                                <button 
                                className="waves-light btn-small red"
                                onClick={()=> toggleInputClient()}
                                ><i className="fas fa-trash-alt"/></button>
                            </div>

                        </div>
                        </form>

                    </div>

                    
                </div>

            </div>



        
        </div>

        </>
    )
}


const CardClient = ({cliente, codigo_cliente, contacto, direccion, rtn, id, deleteClient}) =>{


    return(
    
        <div className="col l12 m12 s12 card-client">
                            
            <div className="col l2 m2 center-align">
                {cliente}
            </div>
            <div className="col l2 m2 center-align">
                {direccion}
            </div>                    
            <div className="col l2 m2 center-align">
                {contacto}
            </div>                   
            <div className="col l2 m2 center-align">
                {rtn}
            </div>                    
            <div className="col l2 m2 center-align">
                {codigo_cliente}
            </div>

            <div className="col l2 m2 center-align">
                
                <div className="buttons-client">
                    
                    <button 
                    className="waves-light btn-small red"
                    onClick={()=> deleteClient(id)}
                    ><i className="fas fa-trash-alt"/></button>
                </div>

            </div>

        </div>
    )

} 