import React from 'react';
import ReactDOM from 'react-dom';
import { CalytekApp } from './CalytekApp';
import './index.css'

import 'normalize.css';

ReactDOM.render(
  <CalytekApp />,
  document.getElementById('root')
);


